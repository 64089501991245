import { Component, OnInit } from '@angular/core';
import { SyllabusService } from '../services/syllabus.service';
import { EmpTaskFilter } from '../../application/enums';
import { UtilService } from 'fx-core';
import * as moment from 'moment';
import { ProjectUtil } from '../../application/services/project-util';

@Component({
  selector: 'emp-tasks-tab',
  templateUrl: './emp-task.component.html',
  styleUrls: ['./emp-task.component.scss']
})
export class EmployeeTaskTabComponent implements OnInit {


  public notices: any = [];
  public student: any = null;

  constructor(public utl: UtilService, public service: SyllabusService, private projectUtil: ProjectUtil) {
    this.student = this.projectUtil.getStudentObj();
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    var auth = this.utl.session.getObject('sessionVariables');

    var inputData = {
      filters: [
        {
          key: EmpTaskFilter.statusId, value: 1
        },
        {
          key: EmpTaskFilter.expectDate, value: moment().format("YYYY-MM-DD")
        }
      ],
      include: [
        { key: "taskType" },
        { key: "taskStatus" },
        { key: "taskAssignStaff" }
      ]
    }


    if (auth.myEmpId && auth.myEmpId > 0) {
      inputData.filters.push({
        key: EmpTaskFilter.taskAssignStaffId, value: auth.myEmpId
      })
    }
    this.service.postData(inputData, 'staff/empTask/get-my-tasks').then(res => this.getListCallback(res));
  }

  getListCallback(res) {
    this.notices = res.data;
  }

}
