import { Component } from '@angular/core';

@Component({
    selector: 'app-payment-success',
    templateUrl: 'payment-success.component.html',
    styleUrls: ['payment-success.component.scss']
})

export class PaymentSuccessComponent {

    constructor() {
    }

    ngOnInit() {

    }
}