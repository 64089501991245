import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-wchome',
    templateUrl: './wchome.component.html',
    styleUrls: ['./wchome.component.scss']
})
export class WCHomeComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }

}
