export enum ConferenceParticipantFilter {
    conferenceParticipantId,
    clientId,
    organizationId,
    institutionId,
    conferenceId,
    participantName,
    participantUserId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ConferenceFilter {
    conferenceId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    participantTypeId,
    conferenceName,
    conferenceDate,
    conferenceTime,
    duration,
    allowRecording,
    description,
    moderatorId,
    moderatorName,
    startTime,
    endTime,
    conferenceStatusId,
    conferenceRoomId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    actualDuration,
    webcamsOnlyForModerator,
    lockSettingsDisablePrivateChat,
    lockSettingsDisableCam,
    lockSettingsDisableNote,
    autoSwapLayout
}
export enum ConferenceSessionFilter {
    conferenceSessionId,
    clientId,
    organizationId,
    institutionId,
    conferenceId,
    conferenceRoomId,
    startTime,
    endTime,
    duration,
    participantCount,
    conferenceXml,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ConferenceSessionUserFilter {
    conferenceSessionUserId,
    conferenceRoomId,
    externalUserId,
    name,
    role,
    joinedTime,
    leftTime,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum FavouriteVideoFilter {
    favouriteVideoId,
    clientId,
    organizationId,
    institutionId,
    videoLibraryId,
    userId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum VideolibraryFilter {
    videoLibraryId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    topic,
    videoUrl,
    uploadById,
    uploadDate,
    sessionDate,
    sessionTime,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    employeeId,
    videoLibraryTypeId,
    description    
}
export enum VideoLibraryLogFilter {
    videoLibraryLogId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    studentId,
    videoLibraryId,
    viewedDate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}

export enum PdfLibraryFilter {
    pdfLibraryId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    description,
    fromDate,
    toDate,
    imagePath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    typeId
}
export enum QuestionBankFilter {
    questionBankId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    questionBankTypeId,
    description,
    fromDate,
    toDate,
    imagePath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}