import { Component, ViewChildren, QueryList, Input } from '@angular/core';
import { OptionsService, UtilService, FileUploadComponent, MessageService } from 'fx-core';
import { BsModalRef } from 'ngx-bootstrap';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
@Component({
    selector: 'student-document-modal',
    templateUrl: 'student-document-modal.component.html',
    styleUrls: ['student-document-modal.component.scss']
})

export class StudentDocumentModalComponent {

    lookup: any = {};
    @Input() uploadFormModel: any;
    valForm: FormGroup;
    //File upload related code
    fileUploadConfig: any = {
        url: "student/studentDocument/saveStuDocumentViaUpload"
    };
    @ViewChildren(FileUploadComponent) fileUploadControls: QueryList<FileUploadComponent>;

    constructor(protected utl: UtilService, private optionsService: OptionsService,
            private messageService: MessageService,
            private modalRef: BsModalRef) {
    }

    ngOnInit() {
        this.initLookup();
    }

    initLookup() {
        const lookupConfig = {
            DocumentType: { default: true }
        };
        const inputData = {
            lookups: lookupConfig,
            filters: {}
        };
        const lookupInputData = {
            data: inputData,
            callback: this.processCallback(this.lookup)
        }

        this.optionsService.getData(lookupInputData);
    }

    processCallback(context) {
        return (res) => {
            for (const key in res) {
                context[key] = res[key];
            }
        };
    }

    saveItem() {
        if(this.fileUploadControls && this.fileUploadControls.length > 0
            && this.fileUploadControls.first.uploader.getNotUploadedItems().length > 0) {
                setTimeout(() => {
                    this.fileUploadControls.forEach((uploadControl) => {
                        if (uploadControl) {
                            uploadControl.uploadDocument();
                        }
                    });
                }, 100);
        }
        else {
            if(this.uploadFormModel.documentTypeId == -1) {
                this.utl.alert.showErrorMsg('Please select attachment type');
                return;
            }
            this.utl.alert.showErrorMsg('Please select document');
            return;
        }
    }

    uploadDocumentCallback(event) {
        this.utl.alert.showSuccessMsg();
        this.messageService.sendMessage("student-document-added", {});
        this.modalRef.hide();
    }

    //File upload related code ends
}
