import { Component, Input } from '@angular/core';
import { LibraryService } from '../services/library.service';
import { UtilService } from 'fx-core';

@Component({
    selector: 'book-info',
    templateUrl: './book-info.component.html',
    styleUrls: ['./book-info.component.scss']
})

export class BookInfoComponent {
    @Input() inputData: any;

    item: any = {
        bookType: {},
        bookCategory: {}
    };
    currentFilter: any = {
        bookId: -1
    };

    constructor(protected utl: UtilService, private libraryService: LibraryService) { }

    ngOnInit() { 
        let rowData = this.inputData.data;
        if (rowData) {
            this.currentFilter.bookId = rowData.bookId || rowData.id;
            this.getBookInfoById();
        }
    }

    getBookInfoById() {
        let inputData: any = {
            id: this.currentFilter.bookId
        };
        this.libraryService.getBookInfoById(inputData).then(res => this.getBookInfoByIdCB(res));
    }

    getBookInfoByIdCB(res) {
        if(res.data && res.data != null) {
            this.item = res.data;
        }
    }
}