import { Component, OnInit } from '@angular/core';
import { ItemCountService } from '../../../services/itemcount.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'fx-core';
import { StudentAttendanceService } from '../../services/studentattendance.service';
import * as Highcharts from "highcharts";

@Component({
  selector: 'portlet-attendance-summary',
  templateUrl: './portlet-attendance-summary.component.html',
  styleUrls: ['./portlet-attendance-summary.component.scss']
})
export class PortletAttendanceSummaryComponent implements OnInit {
  
  Highcharts = Highcharts;
  monthList: any[] = [];
  summaryData: any[] = [];

  constructor(public itemCountService: ItemCountService, protected utl: UtilService, private translate: TranslateService,
        private studentAttendanceService: StudentAttendanceService) { }

  ngOnInit() {
    this.getData();    
  }

  getData() {
    const inputData = {

    };
    this.studentAttendanceService.GetYearlyAttendanceSummary(inputData).then(res => this.getDataCallback(res));
  }

  getDataCallback(res) {
    this.monthList = [];
    this.summaryData = [];
    let result = res.data;
    let tempArray: Number[] = [0,0,0,0,0,0,0,0,0,0,0,0];
    for(let idx in result) {
        let monthData = result[idx];
        let year = monthData.year;        
        if (parseInt(idx) < 12) {
            this.monthList.push(monthData.month);            
        }
        tempArray[(parseInt(idx) % 12)] = monthData.percent;

        if (parseInt(idx) % 12 == 11 || parseInt(idx) == result.length - 1) { // push into array => if it reaches 12th month or if it is last object 
            this.summaryData.push({ name: year, data: tempArray });
            tempArray = [0,0,0,0,0,0,0,0,0,0,0,0];
        }
    }
    this.drawChart();
  }

  
  drawChart() {
    Highcharts.chart('attendancesummary', {
      credits: {
        enabled: false
    },
      chart: {
          type: 'column'
      },
      title: {
          text: 'Attendance Summary'
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories: this.monthList,
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Admission Count'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: this.summaryData
    });
  }
}
