import { NgModule } from '@angular/core';
import { FxCoreModule } from 'fx-core';
import { FileDownloadComponent } from './filedownload/filedownload.component';
import { CameraComponent } from './camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { ImageViewComponent } from './imageview/imageview.component';
import { TreeGridComponent } from './treegrid/treegrid.component';
import { DateTimeDisplayLocalzoneComponent } from './date-time-display-localzone/date-time-display-localzone';
import { DateDisplayFormatComponent } from './date-display-format/date-display-format.component';
import { CurrencyFormatComponent } from './currency-format/currency-format.component';
import { SyllabusNoticeComponent } from './syllabus/syllabus.component';
import { SyllabusService } from './services/syllabus.service'
import { EmployeeTaskTabComponent } from './emp-task/emp-task.component';


@NgModule({
    imports: [FxCoreModule, WebcamModule],
    declarations:
        [
            FileDownloadComponent,
            CameraComponent,
            ImageViewComponent,
            TreeGridComponent,
            DateTimeDisplayLocalzoneComponent,
            DateDisplayFormatComponent,
            CurrencyFormatComponent,
            SyllabusNoticeComponent,
            EmployeeTaskTabComponent
        ],
    exports: [
        FileDownloadComponent,
        CameraComponent,
        ImageViewComponent,
        TreeGridComponent,
        DateTimeDisplayLocalzoneComponent,
        DateDisplayFormatComponent,
        CurrencyFormatComponent,
        SyllabusNoticeComponent,
        EmployeeTaskTabComponent
    ],
    providers: [SyllabusService],
    entryComponents: []
})

export class ProjectComponentsModule { }