export enum ExpenseFilter {
    expenseId,
    clientId,
    organizationId,
    institutionId,
    expenseTypeId,
    expenseReason,
    description,
    referenceNo,
    paymentTypeId,
    chequeNo,
    amount,
    comments,
    expenseStatusId,
    voucherNo,
    voucherDate,
    approvedById,
    approvedDate,
    cancelledById,
    cancelledDate,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ExpenseTypeFilter {
    expenseTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
