export enum AssessmentFormFieldFilter {
    assessmentFormFieldId,
    clientId,
    organizationId,
    institutionId,
    assessmentFormId,
    assessmentFormSectionId,
    assessmentFormFieldName,
    assessmentFormFieldTypeId,
    displayorder,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum AssessmentFormFieldValueFilter {
    assessmentFormFieldValueId,
    clientId,
    organizationId,
    institutionId,
    assessmentFormId,
    assessmentFormFieldId,
    optionText,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum AssessmentFormFilter {
    assessmentFormId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    assessmentFormTypeId,
    assessmentFormCategoryId,
    comments,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum AssessmentFormSectionFilter {
    assessmentFormSectionId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum AssessmentFormTypeFilter {
    assessmentFormTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    formTypeGroupId,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpAssessmentFormFilter {
    empAssessmentFormId,
    clientId,
    organizationId,
    institutionId,
    academicYearId,
    staffTypeId,
    assessmentFormTypeId,
    assessmentFormId,
    endSubmissionDate,
    description,
    empAssessmentFormStatusId,
    submittedDate,
    submittedBy,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpAssFormResponseFilter {
    empAssFormResponseId,
    clientId,
    organizationId,
    institutionId,
    empId,
    staffTypeId,
    academicYearId,
    assessmentFormTypeId,
    assessmentFormId,
    empAssessmentFormId,
    empAssFormResStatusId,
    empAssFormSubmittedDate,
    submittedDate,
    cancelledDate,
    cancelledReason,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpAssFormResponseDetailFilter {
    empAssFormResponseDetailId,
    clientId,
    organizationId,
    institutionId,
    empAssessmentFormId,
    empAssFormResponseId,
    empAssFormResponseHeaderId,
    assessmentFormFieldId,
    assessmentFormFieldValueId,
    answerText,
    attachmentPath,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpAssFormResponseHeaderFilter {
    empAssFormResponseHeaderId,
    clientId,
    organizationId,
    institutionId,
    empAssessmentFormId,
    empAssFormResponseId,
    assessmentFormSectionId,
    assessmentFormFieldId,
    assessmentFormFieldName,
    assessmentFormFieldTypeId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum StuAssFormResponseFilter {
    stuAssFormResponseId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    assessmentFormTypeId,
    assessmentFormId,
    studentAssessmentFormId,
    stuAssFormResStatusId,
    submittedDate,
    cancelledDate,
    cancelledReason,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    stuAssFormSubmittedDate,
}
export enum StuAssFormResponseDetailFilter {
    stuAssFormResponseDetailId,
    clientId,
    organizationId,
    institutionId,
    studentAssessmentFormId,
    stuAssFormResponseId,
    stuAssFormResponseHeaderId,
    assessmentFormFieldId,
    answerText,
    attachmentPath,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    assessmentFormFieldValueId,
}
export enum StuAssFormResponseHeaderFilter {
    stuAssFormResponseHeaderId,
    clientId,
    organizationId,
    institutionId,
    studentAssessmentFormId,
    stuAssFormResponseId,
    assessmentFormSectionId,
    assessmentFormFieldId,
    assessmentFormFieldName,
    assessmentFormFieldTypeId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum StudentAssessmentFormFilter {
    studentAssessmentFormId,
    clientId,
    organizationId,
    institutionId,
    academicYearId,
    courseId,
    batchId,
    sectionId,
    assessmentFormTypeId,
    assessmentFormId,
    endSubmissionDate,
    description,
    stuAssessmentFormStatusId,
    submittedDate,
    submittedBy,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
