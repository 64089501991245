import { Component, Input } from '@angular/core';
import { UtilService, MessageService } from 'fx-core';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { StudentDocumentFilter } from '../../application/enums';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { StudentDocumentModalComponent } from '../student-document-modal/student-document-modal.component';
import { StudentDocumentService } from '../services/studentdocument.service';

@Component({
    selector: 'student-document',
    templateUrl: 'student-document.component.html',
    styleUrls: ['student-document.component.scss']
})

export class StudentDocumentComponent {
    @Input() contextData: any;
    @Input() modalData: any;
    @Input() routeData: any;

    formModel: any = {
        studentId: -1,
        documentTypeId: -1,
        comments: ''
    };
    valForm: FormGroup;
    currentContext: any = {
        studentId: null
    };
    items:any = [];
    subscription: Subscription;

    constructor(protected utl: UtilService, public messageService: MessageService,
            private modalService: BsModalService, private stuDocumentService: StudentDocumentService) {

        this.subscription = this.messageService.getMessage().subscribe(res => {
            if (res && res.type == 'student-document-added') {
               this.refreshDocuments();
            }
        });
    }

    ngOnInit() {
        this.currentContext.studentId = this.routeData.studentId || this.routeData.id;
        this.formModel.studentId = this.currentContext.studentId;
        this.getList();
    }

    getList() {
        if(this.currentContext.studentId > 0) {
            let apiReq = {
                filters: [
                    { key: StudentDocumentFilter.studentId, value: this.currentContext.studentId },
                ],
                include: [{ key: "documentType" }]
            };
            this.stuDocumentService.getStuDocuments(apiReq).then(data => this.getListCallback(data));
        }
    }
    getListCallback(res) {
        this.items = res.data;
    }

    refreshDocuments() {
        this.formModel = {
            studentId: this.currentContext.studentId,
            documentTypeId: -1,
            comments: ''
        }
        this.getList();
    }

    //delete
    deleteStuDocument(item) {
        let inputData = {
            id : item.id
        };
        this.stuDocumentService.deleteStuDocument(inputData).then(data => this.deleteStuDocumentCallback(data));
    }
    deleteStuDocumentCallback(res) {
        this.utl.alert.showSuccessMsg();
        this.getList();
    }

    openDocumentFormModal() {
        let initialState = {
            uploadFormModel:  Object.assign({}, this.formModel)
        };
        this.modalService.show(StudentDocumentModalComponent, { class: 'custom-modal-md', initialState });
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
}
