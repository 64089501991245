import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { AppConfig, UtilService } from 'fx-core';
import { UserPreferenceService } from '../../services/userpreference.service';

@Component({
    selector: 'filedownload-component',
    templateUrl: './filedownload.component.html',
    styleUrls: ['./filedownload.component.scss']
})
export class FileDownloadComponent implements OnInit {

    @Input() control: any;
    @Input() storageType: any;
    @Input() downloadType: any;
    @Input() iconOnly: any;

    private _filePath: any;
    @Input()
    set filePath(parentData: any) {
        this._filePath = parentData;
        this.computeFileName();
    }
    get filePath(): any { return this._filePath; }

    fileName: any;
    constructor(public utl: UtilService) {
    }

    ngOnInit() {

    }

    computeFileName() {
        let fileName = "";
        if (this.filePath) {
            if (this.filePath.lastIndexOf('\\') > -1) {
                fileName = this.filePath.substring(this.filePath.lastIndexOf('\\') + 1);
            } else  if (this.filePath.lastIndexOf('/') > -1) {
                fileName = this.filePath.substring(this.filePath.lastIndexOf('/') + 1);
            } else {
                if (this.filePath.lastIndexOf('.') > -1) {
                    let fileExtension = this.filePath.substring(this.filePath.lastIndexOf('.') + 1);
                    fileName = `download.${fileExtension}`;
                } else {
                    fileName = "download";
                }
            }
            this.fileName = fileName;
        }
    }

    //download file
    downloadFile() {
        let inputData = { url: this.filePath };
        let actionUrl = this.storageType == "public" ? "exports/getPublicFile" :  "exports/getFile";
        var options: any = {
            fileName: this.fileName,
            action: actionUrl,
            type: 'post',
            data: inputData
        };

        if(this.downloadType == "open") {
            let fileExtension = '';
            if (this.filePath.lastIndexOf('.') > -1) {
                fileExtension = this.filePath.substring(this.filePath.lastIndexOf('.') + 1);
            }
            if(fileExtension == "pdf" || fileExtension == "jpeg" || fileExtension == "jpg") {
                this.utl.http.doOpen(options);
            }  else {
                this.utl.http.doDownload(options);
            }
        } else {
            this.utl.http.doDownload(options);
        }
    }
}
