import { Component, OnInit, Input } from '@angular/core';
import { AppConfig, UtilService, MessageService } from 'fx-core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'treegrid-component',
    templateUrl: './treegrid.component.html',
    styleUrls: ['./treegrid.component.scss']
})
export class TreeGridComponent implements OnInit {

    @Input() templateConfig: any;
    @Input() filterConfig: any;
    subscription: Subscription;
    
    item: any = {};

    constructor(public utl: UtilService, private http: HttpClient, public messageService: MessageService) {
        this.subscription = this.messageService.getMessage().subscribe(res => {
            if (res && res.type == 'treegridrefresh') {
                this.item = res.data;
                this.getRootConfigData();
            }
        });
    }

    ngOnInit() {
        this.item = this.filterConfig;
        this.getRootConfigData();
    }

    getRootConfigData() {
        this.getData(this.templateConfig.apiConfig, this.item);
    }

    getData(config, context) {
        var options: any = {
            action: config.api,
            type: config.type,
            data: context,
            callback: this.getDataCallback(context)
        };

        this.utl.http.doAction(options).then(res => {
            let result = res && res.data ? res.data : res;
            options.callback(result, config);
        });
        return null;
    }

    getDataCallback(context) {
        return (res) => {
            for(let item of res) {
                item['rowExpanded'] = false;
            }
            context['childRecords'] = res;
        };
    }

    expandRow(config, item) {
        item.rowExpanded=true;
        this.getData(config.apiConfig, item);
    }

    collapseRow(item) {
        item.rowExpanded=false;
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    
    getformattedTime(val) {
        if(val) {
            return moment(val, "HHmm").format("hh:mm A")
        }
    }
}
