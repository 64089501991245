import { Component, ViewChildren, QueryList } from '@angular/core';
import { OptionsService, UtilService, FileUploadComponent, MessageService } from 'fx-core';
import { BsModalRef } from 'ngx-bootstrap';
import { StudentDocumentService } from '../services/studentdocument.service';

@Component({
    selector: 'student-import-modal',
    templateUrl: 'student-import-modal.component.html',
    styleUrls: ['student-import-modal.component.scss']
})

export class StudentImportModalComponent {

    //File upload related code
    fileUploadConfig: any = {
        url: "imports/excel/Student",
        canShowUpload: true

    };
    uploadFormModel: any = {};
    @ViewChildren(FileUploadComponent) fileUploadControls: QueryList<FileUploadComponent>;

    constructor(protected utl: UtilService, private optionsService: OptionsService,
        private stuDocumentService: StudentDocumentService,
            private messageService: MessageService,
            private modalRef: BsModalRef) {
    }

    ngOnInit() { 
    } 

    uploadDocumentCallback(event) {
        this.modalRef.hide();
    }

    sampleFile() {
        this.stuDocumentService.openFile({},this.fileUploadConfig.url);
    }

}
