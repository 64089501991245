import { Injectable } from "@angular/core";
import { IProjectService, Constants, PrivilegeService } from "fx-core";
import * as moment from 'moment';
import { FilterMapService } from "../enums/filter.map";
import * as _ from 'lodash';

@Injectable()
export class ProjectService implements IProjectService {
  constructor(public filterMapService: FilterMapService, public privilegeService : PrivilegeService) {
  }
  formatCurrency(data) {
    return data;
  }
  getComputedDate(data) {
    return data;
  }

  getResolveValueKeys(type: string) {
    if (type == "date") {
      return [
        { code: 'currentDate', text: 'Current Date' },
        { code: 'dayMinusSeven', text: 'Day Minus Seven' },
        { code: 'monthStartDate', text: 'Month Start Date' },
        { code: 'yearStartDate', text: 'Year Start Date' },
        { code: 'app.pref.FirstDay', text: 'Preference First Day' }
      ];
    }
  }
  
  processGridOptions(options, apiConfig) {
    let filters = this.filterMapService.computeFilters(apiConfig);
    options.data = { filters: filters, sorters: [], include: apiConfig.includes };
    return options;
  }

  computeAPIServiceOptions(options, apiConfig) {
    //Caution: dont move this line below processGridOptions call, as it overrides options
    let startIndex = options.data.data.startIndex;
    let pageSize = options.data.data.pageSize;
    this.processGridOptions(options, apiConfig);
    let pageNumber = (startIndex/pageSize) + 1;
    options.data.pageContext = {
    	pageSize: pageSize,
    	pageNumber: pageNumber
    };
    return options; 
  }

  preProcessExport(options, apiConfig, exportConfig) {
    this.processGridOptions(options, apiConfig);

    if (apiConfig && apiConfig.api && apiConfig.api.indexOf("{{format}}") > 0) {
      _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
      let compiled = _.template(apiConfig.api);

      options.action =  compiled({format : 'excel'});
      options.type = apiConfig.type;
    } else {
      options.action = "exports/excel";
      options.type = "post";
      options.data = {
        key: exportConfig.key,
        request: options.data
      };
    }
    if (apiConfig.inputparams && apiConfig.inputparams.columnDefinitions) {
      let attributes = [];
      for (let colDef of apiConfig.inputparams.columnDefinitions) {
        if (colDef.Name && colDef.Name.toLowerCase() != "actions") {
          let attribute = [colDef.Name, colDef.Display];
          if (colDef.DataType == "date") {
            attribute = [colDef.Name, colDef.Display, Constants.DateFormat];
          }
          attributes.push(attribute);
        }
      }
      options.data.order = attributes;
    }
    options.fileName = exportConfig.key || options.fileName;
    return options;
  }

  preProcessExportPdf(options, apiConfig, exportConfig) {
    this.processGridOptions(options, apiConfig);

    if (apiConfig && apiConfig.api && apiConfig.api.indexOf("{{format}}") > 0) {
      _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
      let compiled = _.template(apiConfig.api);
      options.action =  compiled({format : 'pdf'});
      options.type = apiConfig.type;
    } else {
      options.action = "exports/pdf";
      options.type = "post";
      options.data = {
        key: exportConfig.key,
        request: options.data
      };
    }

    if (apiConfig.inputparams && apiConfig.inputparams.columnDefinitions) {
      let attributes = [];
      for (let colDef of apiConfig.inputparams.columnDefinitions) {
        if (colDef.Name && colDef.Name.toLowerCase() != "actions") {
          let attribute = [colDef.Name, colDef.Display];
          if (colDef.DataType == "date") {
            attribute = [colDef.Name, colDef.Display, Constants.DateFormat];
          }
          attributes.push(attribute);
        }
      }
      options.data.order = attributes;
    }
    options.fileName = exportConfig.key || options.fileName;
    return options;
  }

  preProcessLookupOptions(config) {
    let result = this.filterMapService.computeLookups(config.data);
    return result;
  }

  preProcessFormAction(config) {
    let result = { data: config.data };
    return result;
  }

  hasPrivilege(privilege) {
    return this.privilegeService.has(privilege);
  }
}
