import { Component, Input } from "@angular/core";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { OptionsService, UtilService } from "fx-core";
import { HostelMgmtService } from "../../hostelmgmt/services/hostelmgmt.service";
import { StudentService } from "../../hostelmgmt/services/student.service";
import {
  StudentFilter,
  RoomBedFilter,
  StudentRoomFilter,
  InstitutionFilter
} from "../../application/enums";
import { ProjectUtil } from "../../application/services/project-util";

@Component({
  selector: "shared-student-room-assignment",
  templateUrl: "room-assignment.component.html",
  styleUrls: ["room-assignment.component.scss"],
})
export class SharedStudentRoomAssignmentComponent {
  @Input() contextData: any;
  @Input() modalData: any;
  @Input() routeData: any;

  item: any = {
    hostelTypeId: -1,
    blockId: -1,
    floorId: -1,
    roomMasterId: -1,
    roomBedId: -1,
    roomTypeId: -1,
    ebNoId: -1,
    roomCharges: 0,
    maintenanceCharges: 0,
    foodCharges: 0,
    otherCharges: 0,
    comments: "",
    chargeTypeId: -1,
    graceDays: 0,
    lateFees: 0,
  };
  feesGroupData: any; //student alloted fee group. Eg: hostel, code used - hostelfee 
  institutes:any = [];
  createFeesServiceToStudent: any = {
    academicYearId: "",
    amount: "",
    batchId: "",
    courseId: "",
    feeServiceId: "",
    studentFeeId: "",
    studentId: "",
    welfareAmount: 0
  };

  studentDetails: any = {
    course: {},
    batch: {},
    section: {},
    studentStatus: {},
  };

  currentFilter: any = {
    name: "",
    uniqueId: "",
    academicYearId: -1,
    institutionId: -1,
  };

  selectedRoomBedDetails: any = {};

  lookup: any = {};
  dropDownData: any = [];

  constructor(
    protected utl: UtilService,
    private translate: TranslateService,
    public router: Router,
    public hostelMgmtService: HostelMgmtService,
    protected studentService: StudentService,
    public optionsService: OptionsService,
    private projectUtil: ProjectUtil
  ) {}

  ngOnInit() {
    var authToken = this.utl.session.getObject('authtoken');
    this.currentFilter.institutionId = authToken && authToken.user ? authToken.user.institutionId : -1;
    this.currentFilter.academicYearId =
      this.projectUtil.getCurrentAcademicYear();
    this.init();
    this.initLookup();
    this.getAllInstituteByOrg();
  }

  init() {
    let routeData = this.utl.nav.peek();
    console.log(routeData, 'room');
    if (routeData && routeData.targetData) {
      this.currentFilter.institutionId = routeData.targetData.institutionId;
      this.currentFilter.studentId = routeData.targetData.studentId;
      if(routeData.targetData.studentUserId){
        this.currentFilter.studentId = routeData.targetData.id;
      }
      this.getStudentData();
    }
  }

    //get institution by organisation
    getAllInstituteByOrg(){
      var authToken = this.utl.session.getObject('authtoken');
      const orgId = authToken && authToken.user ? authToken.user.organizationId: '';

      if(orgId){
          const  inputData = {
              filters: [
                  {key: InstitutionFilter.organizationId, value: orgId},
                  {key: InstitutionFilter.isActive, value: 1}
              ]
          }
          this.studentService.getAllInsByOrg(inputData).then(res => this.insCallback(res));
      }
  }

  insCallback(res) {
      const temp = [];
      if(res.data){
          res.data.forEach(i => {
              temp.push({
                  id: i.id,
                  text: i.name,
                  code: i.code,
              })
          });
      }
      this.institutes = temp;
  }

  studentsDropDown(e, type: string = "", allow: boolean = false) {
    if ((e && e.target && e.target.value) || allow === true) {
      if (type === "N") {
        this.currentFilter.name = e.target.value;
      } else if (type === "I") {
        this.currentFilter.uniqueId = e.target.value;
      }
      const filterParams: any = {
        filters: [],
        include:[
          { key: "course" },
          { key: "batch" }
        ],
        pageContext: {
          pageNumber: 1,
          pageSize: 50,
        },
      };

      if (this.currentFilter.name) {
        filterParams.filters.push({
          key: 6,
          value: this.currentFilter.name,
          searchType: 1,
        });
      }
      if (this.currentFilter.uniqueId) {
        filterParams.filters.push({
          key: 20,
          value: this.currentFilter.uniqueId,
        });
      }

      if(this.currentFilter.institutionId){
        filterParams.filters.push({key: StudentFilter.institutionId, value: this.currentFilter.institutionId, searchType: 1})
      }

      if (filterParams.filters.length > 0) {
        this.studentService.getStudentDetailsByIds(filterParams).then(
          (res) => {
            const { data } = res;
            if (data && data.length > -1) {
              this.dropDownData = data;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  setFilterName(nameSearch, data) {
    this.currentFilter.name = nameSearch;
    this.currentFilter.uniqueId = data.studentIdentifier;
    this.dropDownData = [];
    this.getStudentData();
  }

  getStudentData() {
    let inputData: any = {};
    if (
      this.currentFilter.name.length > 0 ||
      this.currentFilter.uniqueId.length > 0 ||
      this.currentFilter.studentId
    ) {
      inputData = {
        filters: [
          {
            key: StudentFilter.firstName,
            value: this.currentFilter.name,
            searchType: 1,
          },
        ],
        include: [
          { key: "course" },
          { key: "batch" },
          { key: "section" },
          { key: "studentStatus" },
        ],
      };
      if (this.currentFilter.uniqueId.length > 0) {
        inputData.filters.push({
          key: StudentFilter.studentIdentifier,
          value: this.currentFilter.uniqueId,
        });
      }

      if(this.currentFilter.institutionId){
        inputData.filters.push({key: StudentFilter.institutionId, value: this.currentFilter.institutionId, searchType: 1})
      }

      if (this.currentFilter.studentId > 0) {
        inputData.filters.push({
          key: StudentFilter.studentId,
          value: this.currentFilter.studentId,
        });
      }
      this.studentService
        .getStudentDetails(inputData)
        .then((res) => this.getStudentDataCallback(res));
    }
  }

  getStudentDataCallback(res) {
    this.studentDetails = res.data;
    if (this.studentDetails) {
      this.currentFilter.name = this.studentDetails.firstName;
      this.currentFilter.uniqueId = this.studentDetails.studentIdentifier;
      this.currentFilter.academicYearId = this.studentDetails.academicYearId;

      // reset studentId to search for other students
      this.currentFilter.studentId = -1;
    }
    this.getStudentRoomAssignment();
    this.getStudentFeesService();
  }

  getStudentFeesService(){
    var inputData = {
      filters: [
        { key: 6, value:  this.studentDetails.id},
        {key: 7, value: 1}
      ],
      include: [{key: "feeGroup"}]
    };

    this.hostelMgmtService
    .getStudentFeesDetails(inputData)
    .then((res) => this.getStudentFeesCallback(res));
  }

  getStudentFeesCallback(res) {
    const {data} = res;
    const feeServices = this.lookup.FeeService.filter(i => {
      return (i.code).toLowerCase() === 'hostelfee'
    });

    if(data && data.length > 0){
      data.forEach($item => {
        if($item.feeGroup && ($item.feeGroup.feeGroupCode).toLowerCase() === "hostelfee"){
          this.feesGroupData = $item;
          this.createFeesServiceToStudent = {
            academicYearId: this.studentDetails.academicYearId,
            batchId: this.studentDetails.batchId,
            courseId: this.studentDetails.courseId,
            feeServiceId: feeServices[0].id,
            studentFeeId: this.feesGroupData.id,
            studentId: this.studentDetails.id,
            welfareAmount: 0
          };
        }
      });
    }
  }

  getStudentRoomAssignment() {
    this.reset();
    if (this.studentDetails.id > 0) {
      var inputData = {
        filters: [
          { key: StudentRoomFilter.studentId, value: this.studentDetails.id },
        ],
      };
      if (this.currentFilter.academicYearId > 0) {
        inputData.filters.push({
          key: StudentRoomFilter.academicYearId,
          value: this.currentFilter.academicYearId,
        });
      }

      this.hostelMgmtService
        .getStudentRoomDetails(inputData)
        .then((res) => this.getStudentRoomBedDetailsCallback(res));
    }
  }

  getStudentRoomBedDetailsCallback(res) {
    if (res.data.length > 0) {
      const item = _.find(res.data, function (i) {
        return i.id > 0;
      });
      if (item) {
        this.item = item;
        // get lookup values for floor room and bed
        this.getLookupsForFloorRoomBed();
      }
    }
  }

  getRoomBedDetails() {
    var inputData = {
      filters: [{ key: RoomBedFilter.roomBedId, value: this.item.roomBedId }],
      include: [{ key: "roomMaster" }],
    };
    this.hostelMgmtService
      .getRoomBedDetails(inputData)
      .then((res) => this.getRoomBedDetailsCallback(res));
  }

  getRoomBedDetailsCallback(res) {
    if (res.data.length > 0) {
      let roomDetails = res.data[0].room;
      this.selectedRoomBedDetails = roomDetails;
      this.item.roomTypeId = roomDetails.roomTypeId ? roomDetails.roomTypeId : -1;
      this.item.ebNoId = roomDetails.ebNoId ? roomDetails.ebNoId : -1;
      this.item.roomCharges = roomDetails.roomType ? roomDetails.roomType.roomCharge : '';
      this.item.maintenanceCharges = roomDetails.roomType ? roomDetails.roomType.maintenance : '';
      this.item.foodCharges = roomDetails.roomType ? roomDetails.roomType.foodCharge : '';
      this.item.otherCharges = roomDetails.roomType ? roomDetails.roomType.others : '';
    }
  }

  admission() {
    this.item.academicYearId = this.currentFilter.academicYearId;
    this.item.studentId = this.studentDetails.id;
    if (this.item.studentId > 0 && this.item.roomMasterId > 0) {
      const reqBody: any = this.item;

      if(this.studentDetails){
        reqBody.institutionId = this.studentDetails.institutionId;
      }

      var inputData = {
        data: reqBody,
      };

      if (this.item.id > 0) {
        this.hostelMgmtService
          .updateStudentRoomDetails(inputData)
          .then((res) => this.admissionCallback(res));
      } else {
        this.hostelMgmtService
          .addStudentRoomDetails(inputData)
          .then((res) => this.admissionCallback(res));
      }
    }
  }

  syncFees() {

    var amt: number = 0;
    if(this.item){
      if(this.item.foodCharges){
        amt += parseFloat(this.item.foodCharges);
      }

      if(this.item.roomCharges){
        amt += parseFloat(this.item.roomCharges);
      }

      if(this.item.otherCharges){
        amt += parseFloat(this.item.otherCharges);
      }

    }
    this.createFeesServiceToStudent.amount = amt;

    if(! this.feesGroupData){
      this.utl.alert.showErrorMsg("Fee service is mandatory for sync fees.");
      return false;
    }
    this.item.academicYearId = this.currentFilter.academicYearId;
    this.item.studentId = this.studentDetails.id;
    if (this.item.studentId > 0 && this.item.roomMasterId > 0) {
      const reqBody: any = this.item;

      if(this.studentDetails){
        reqBody.institutionId = this.studentDetails.institutionId;
      }

      var inputData = {
        data: reqBody,
      };
      if (this.item.id > 0) {
        this.hostelMgmtService
          .updateStudentRoomDetails(inputData)
          .then((res) => this.syncFeesCallBack(res));
      } else {
        this.hostelMgmtService
          .addStudentRoomDetails(inputData)
          .then((res) => this.syncFeesCallBack(res));
      }

      // this.hostelMgmtService
      // .syncFees(inputData)
      // .then((res) => this.syncFeesCallBack(res));
    }
  }


  syncFeesCallBack(res) {
      this.hostelMgmtService.addFeesServices({data: this.createFeesServiceToStudent })
      this.utl.alert.showSuccessMsg();
      this.router.navigate(["/hostelmgmt/studentroomassignments"]);
  }

  admissionCallback(res) {
    this.utl.alert.showSuccessMsg();
    this.router.navigate(["/hostelmgmt/studentroomassignments"]);
  }

  vacant() {
    this.item.academicYearId = this.currentFilter.academicYearId;
    this.item.studentId = this.studentDetails.id;
    if (this.item.studentId > 0 && this.item.roomMasterId > 0) {
      if (this.item.id > 0) {
        this.hostelMgmtService
          .vacantStudentRoom(this.item)
          .then((res) => this.vacantCallback(res));
      }
    }
  }

  vacantCallback(res) {
    this.utl.alert.showSuccessMsg();
    this.getStudentRoomAssignment();
  }

  initLookup() {
    var lookupConfig = {
      AcademicYear: { default: true },
      HostelType: { default: true },
      Block: { default: true },
      RoomType: { default: true },
      EbNo: { default: true },
      ChargeType: { default: true },
      Institution: { default: true, },
      FeeService: { default: false },
    };
    var inputData = {
      lookups: lookupConfig,
      filters: {},
    };
    var lookupInputData = {
      data: inputData,
      callback: this.processCallback(this.lookup),
    };

    this.optionsService.getData(lookupInputData);
  }

  processCallback(context) {
    return (res) => {
      for (var key in res) {
        context[key] = res[key];
      }
    };
  }

  getLookupsForFloorRoomBed() {
    this.blockChanged();
    this.floorChanged();
    this.roomChanged();
  }

  //Cascading lookup
  blockChanged() {
    var lookupConfig = {};
    var filters = {};

    lookupConfig["Floor"] = { default: true };
    filters["blockId"] = this.item.blockId;

    this.getSpecificLookups(lookupConfig, filters);
  }

  floorChanged() {
    var lookupConfig = {};
    var filters = {};

    lookupConfig["RoomMaster"] = { default: true };
    filters["floorId"] = this.item.floorId;

    this.getSpecificLookups(lookupConfig, filters);
  }

  roomChanged() {
    var lookupConfig = {};
    var filters = {};

    lookupConfig["RoomBed"] = { default: true };
    filters["roomMasterId"] = this.item.roomMasterId;

    this.getSpecificLookups(lookupConfig, filters);

    this.computeRoomType();
  }

  computeRoomType() {
    let roomMaster: any = _.find(this.lookup.RoomMaster, {
      id: this.item.roomMasterId,
    });
    this.item.roomTypeId = roomMaster.roomTypeId;

    let roomType: any = _.find(this.lookup.RoomType, {
      id: this.item.roomTypeId,
    });
    if (roomType) {
      this.item.roomCharges = roomType.roomCharge;
      this.item.maintenanceCharges = roomType.maintenance;
      this.item.foodCharges = roomType.foodCharge;
      this.item.otherCharges = roomType.others;
    }
  }

  //get specific lookup
  processSpecificLookupCallback(context) {
    return (res) => {
      for (var key in res) {
        context[key] = res[key];
      }
    };
  }
  getSpecificLookups(lookupConfig, filters) {
    if (!this.utl.isEmptyJSONObject(lookupConfig)) {
      var inputData = {
        lookups: lookupConfig,
        filters: filters,
      };

      var lookupInputData = {
        data: inputData,
        callback: this.processSpecificLookupCallback(this.lookup),
      };
      this.optionsService.getData(lookupInputData);
    }
  }

  getBedStatus(item){
    if(item && item.text.toLowerCase() === 'occupied'){
      return false;
    }
    return true;
  }

  reset() {
    this.item = {
      studentId: -1,
      hostelTypeId: -1,
      blockId: -1,
      floorId: -1,
      roomMasterId: -1,
      roomBedId: -1,
      roomTypeId: -1,
      ebNoId: -1,
      roomCharges: 0,
      maintenanceCharges: 0,
      foodCharges: 0,
      otherCharges: 0,
      comments: "",
      chargeTypeId: -1,
      graceDays: 0,
      lateFees: 0,
    };
  }
}
