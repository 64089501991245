import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class PassengerAssignmentService {
    constructor(public utl: UtilService) {

    }
    getStudentDetails(inputData) {
        var options: any = {
            action: 'student/student/getStudentBySearch',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getTransPortServicesGroup(inputData) {
        var options: any = {
            action: 'student/studentFee/getStudentFeesForFeeCollect',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    addTransportServices(inputData) {
        var options: any = {
            action: 'student/studentfee/addStudentFeeDetail',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getBoardingPoints(inputData) {
        var options: any = {
            action: 'transport/routeBoardingPointMap/getRouteBoardingPointMaps',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getVehicleDetails(inputData) {
        var options: any = {
            action: 'transport/bus/getBusByRouteId',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    savePassengerDetail(inputData) {
        let actionURL = '';
        if(inputData.data.id > 0) {
            actionURL = 'transport/boardingPointPassenger/updateBoardingPointPassenger';
        } else {
            actionURL = 'transport/boardingPointPassenger/addBoardingPointPassenger';
        }
        var options: any = {
            action: actionURL,
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }
}