import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { Router } from '@angular/router';
import { UtilService } from 'fx-core';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.html',
    styleUrls: ['./forgot-password.scss']
})
export class ForgotPasswordComponent implements OnInit {

    currentContext: any = {
        screenview: 'username'
    }

    item: any = {};
    constructor(public settings: SettingsService, public router: Router, public utl: UtilService, private userService: UserService) {

    }

    ngOnInit() {

    }

    gotoLoginPage() {
        this.utl.nav.navigate({ targetRoute: '/login' });
    }

    generateOTP() {
        if (this.item.userName) {
            let inputData = {
                data: {
                    userName: this.item.userName
                }
            };
            this.userService.GenerateOTP(inputData).then(data => this.generateOTPCallback(data));
        }
    }

    generateOTPCallback(res) {
        if (res.data > 0) {
            this.currentContext.userId = res.data;
            this.currentContext.screenview = 'otp';
        }
    }

    verifyOTP() {
        if (this.currentContext.userId > 0) {
            let inputData = {
                data: {
                    userId: this.currentContext.userId,
                    otp: this.item.otp
                }
            };
            this.userService.VerifyOTP(inputData).then(data => this.verifyOTPCallback(data));
        }
    }

    verifyOTPCallback(res) {
        if (res) {
            this.currentContext.screenview = 'password';
        } else {
            this.utl.alert.showErrorMsg('Invalid OTP');
            this.currentContext.screenview = 'username';
        }
    }

    savePassword() {
        if (this.isPasswordValidated()) {
            let inputData = {
                data: {
                    userId: this.currentContext.userId,
                    newPassword: this.item.newPassword,
                    confirmPassword: this.item.confirmPassword
                }
            };
            this.userService.savePassword(inputData).then(data => this.savePasswordCallback(data));
        }
    }

    savePasswordCallback(res) {
        if (res) {
            this.utl.alert.showSuccessMsg();
            this.gotoLoginPage();
        }
    }

    isPasswordValidated() {
        return this.item.newPassword == this.item.confirmPassword;
    }


}
