const Controls = {
    text: 'Controls',
    link: '/superadmin/controls',
    icon: ''
};

const ReferenceGroups = {
    text: 'Reference Groups',
    link: '/admin/referencegroups',
    icon: ''
};

const Clients = {
    text: 'Clients',
    link: '/superadmin/clients',
    icon: ''
};

const Organizations = {
    text: 'Training Partners',
    link: '/admin/organizations',
    icon: ''
};


const Programs = {
    text: 'Training Programs',
    link: '/admin/programs',
    icon: ''
};

const Institutions = {
    text: 'Training Centers',
    link: '/admin/institutions',
    icon: ''
};

const PartnerInstitutions = {
    text: 'Training Centers',
    link: '/partners/institutions',
    icon: 'fas fa-building'
};


const States = {
    text: 'States',
    link: '/configuration/states',
    icon: ''
};

const Districts = {
    text: 'Districts',
    link: '/configuration/districts',
    icon: ''
};

const Cities = {
    text: 'Cities',
    link: '/configuration/cities',
    icon: ''
};

const Countries = {
    text: 'Countries',
    link: '/configuration/countries',
    icon: ''
};

const Nationalities = {
    text: 'Nationality',
    link: '/configuration/nationality',
    icon: ''
};

const Modules = {
    text: 'Modules',
    link: '/admin/modules',
    icon: ''
};

const DefaultTemplates = {
    text: 'Default Templates',
    link: '/admin/defaulttemplates',
    icon: ''
}

const DefaultReports = {
    text: 'Default Reports',
    link: '/admin/defaultreports',
    icon: ''
}

const RptInstitutions = {
    text: 'Company List',
    link: '/rpt/rptinstitutionlist',
    icon: ''
};

const RptLicenseExpiredInstitutions = {
    text: 'License Expired Company',
    link: '/rpt/rptinstitutionlicense',
    icon: ''
};

const ConferenceSummary = {
    text: 'Conference Summary',
    link: '/admin/conferencesummary',
    icon: ''
}

const VideoUpload = {
    text: 'Video Upload',
    link: '/staff/videoupload',
    icon: ''
}


const CourseCertificates = {
    text: 'Course Certificates',
    link: '/student/release-certificate',
    icon: ''
}

const VideoLibrary = {
    text: 'Video Library',
    link: '/videoconference/videolibraries-admin',
    icon: ''
}

const ContentLibrary = {
    text: 'Content Library',
    link: '/videoconference/contentlibrary-admin',
    icon: ''
}

const Courses = {
    text: 'Courses',
    link: '/configuration/courses',
    icon: ''
}

const Subjects = {
    text: 'Subjects',
    link: '/configuration/subjects',
    icon: ''
}

const Academicyears = {
    text: 'Academic years',
    link: '/configuration/academicyears',
    icon: ''
}

const Scheme = {
    text: 'Scheme',
    link: '/configuration/batches',
    icon: ''
}

const AcedmicCategory = {
    text: 'Admission Qualification Category',
    link: '/configuration/admissioncategories',
    icon: ''
}

const Batch = {
    text: 'Batch',
    link: '/configuration/sections',
    icon: ''
}
const Fee = {
    text: 'Fee Collect',
    icon: '',
    link: '/fee/feecollectallinstitute'
}
const Trainer = {
    text: 'Trainers',
    link: '/hrmemp/allemployees',
    icon: ''
}

const TrainerBypartner = {
    text: 'Trainers',
    link: '/hrmemp/employeesbypartners',
    icon: ''
}

const Students = {
    text: 'Students',
    link: '/student/allStudents',
    icon: ''
}

const StudentsPartner = {
    text: 'Students',
    link: '/student/studentsbypartner',
    icon: ''
}

const superAdmin = {
    text: 'Apex Admin',
    link: '',
    icon: 'fas fa-user-cog',
    submenu: [
        Clients,
        Organizations,
        Programs,
        Institutions,
        ReferenceGroups,
        Countries,
        States,
        Districts,
        Cities,
        Nationalities,
        Modules,
        Controls,
        DefaultTemplates,
        DefaultReports,
        VideoUpload
    ]
};
const reports = {
    text: 'Reports',
    link: '',
    icon: 'fas fa-clipboard-list',
    submenu: [
        RptInstitutions,
        RptLicenseExpiredInstitutions,
        CourseCertificates

    ]
};

const econtents = {
    text: 'E-Contents',
    link: '',
    icon: 'fas fa-users',
    submenu: [
        VideoLibrary,
        ContentLibrary,
    ]
};

const academicConfig = {
    text: 'Academic Configuration',
    link: '',
    icon: 'fas fa-cogs',
    submenu: [
        Courses,
        Scheme,
        Batch,
        Subjects,
        Academicyears,
        AcedmicCategory,
        Fee
    ]
};


const academicConfigPartner = {
    text: 'Academic Configuration',
    link: '',
    icon: 'fas fa-cogs',
    submenu: [
        Fee
    ]
};

const videoconference = {
    text: 'Video Conference',
    link: '',
    icon: 'fas fa-video',
    submenu: [
        ConferenceSummary
    ]
};


const trainers = {
    text: 'Trainers & Students',
    link: '',
    icon: 'fas fa-id-card',
    submenu: [
        Trainer,
        Students
    ]
};
const trainersByPartner = {
    text: 'Trainers & Students',
    link: '',
    icon: 'fas fa-id-card',
    submenu: [
        TrainerBypartner,
        StudentsPartner
    ]
};

export const menu = [
];

export const allmenu = {
    1: [
        superAdmin,
        reports,
        academicConfig,
        trainers,
        econtents,
        videoconference
    ]
};

export const partnermenu = {
    1: [
        PartnerInstitutions,
        trainersByPartner,
        academicConfigPartner
    ]
};
