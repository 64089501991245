export enum AnnouncementFilter {
    announcementId,
    clientId,
    organizationId,
    institutionId,
    receiverTypeId,
    courseId,
    batchId,
    sectionId,
    announcementTypeId,
    announcementDate,
    title,
    announcement,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    attachmentPath
}
export enum AssignmentFilter {
    assignmentId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    submissionDate,
    message,
    attachmentPath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    assignmentDate,
    assignmentById,
    employeeId,
    isSubmissionRequired
}
export enum ClassDiaryFilter {
    classDiaryId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    diaryDate,
    subjectId,
    description,
    attachmentPath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    diaryById,
    employeeId
}
export enum ClassGalleryDetailFilter {
    classGalleryDetailId,
    clientId,
    organizationId,
    institutionId,
    classGalleryId,
    description,
    imagePath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ClassGalleryFilter {
    classGalleryId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    subjectId,
    galleryName,
    galleryDate,
    userId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ClassNoteFilter {
    classNoteId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    noteDate,
    assignedById,
    referenceUrl,
    description,
    attachmentPath,
    classNoteStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    employeeId
}
export enum EmpAttendanceFilter {
    empAttendanceId,
    clientId,
    organizationId,
    institutionId,
    empId,
    inTime,
    outTime,
    staffAttendanceStatusId,
    remarks,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    attendanceDate,
    staffTypeId,
    staffPresentTypeId,
    lop,
    staffLeaveDayId,
    leaveTypeId,
    shiftTypeId,
    designationId,
    attendanceVerificationStatusId,
}
export enum ExamScheduleFilter {
    examScheduleId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    academicYearId,
    examTypeId,
    subjectId,
    examDate,
    sessionId,
    referenceUrl,
    description,
    attachmentPath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    sectionId
}
export enum MsgFilter {
    msgId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    receiverTypeId,
    messageTypeId,
    messagePriorityId,
    subject,
    message,
    messageById,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    messageDate
}
export enum ParentCommentFilter {
    parentCommentId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    studentId,
    commentDate,
    comments,
    reviewerId,
    reviewStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum PeriodTimingFilter {
    periodTimingId,
    clientId,
    organizationId,
    institutionId,
    name,
    fromTime,
    toTime,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    displayOrder,
}
export enum StaffsubjectFilter {
    staffSubjectId,
    clientId,
    organizationId,
    institutionId,
    staffId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    academicYearId,
    isDefault,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TeacherCommentFilter {
    teacherCommentId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    commentDate,
    comments,
    commentById,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TestGradeMasterFilter {
    testGradeMasterId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    markRangeFrom,
    markRangeTo,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TimeTableFilter {
    timeTableId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    dayId,
    periodTimingId,
    subjectId,
    colorCode,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    staffId,
    isTimeTableUpload,
    attachmentPath
}
export enum StudentoutpassFilter {
    studentOutpassId,
    clientId,
    organizationId,
    institutionId,
    outpassId,
    studentId,
    courseId,
    batchId,
    sectionId,
    outpassDate,
    pickupTime,
    returnDate,
    dropTime,
    studentOutpassReasonId,
    studentOutpassPickupById,
    pickupByName,
    photoUrl,
    comments,
    adminComments,
    requestedDate,
    checkoutDate,
    checkoutTime,
    checkinDate,
    checkinTime,
    outpassRequestStatusId,
    approvedById,
    approvedDate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum YearCalendarPlanFilter {
    yearCalendarPlanId,
    clientId,
    organizationId,
    institutionId,
    academicYearId,
    fromDate,
    toDate,
    event,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ShiftTypeFilter {
    shiftTypeId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    startTime,
    endTime,
    graceTime,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum StudentTestDetailFilter {
    studentTestDetailId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    studentTestId,
    testDetailId,
    testTypeId,
    testId,
    parentTestId,
    mark,
    maxMark,
    grade,
    remarks,
    signature,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    minMark,
    preMark,
    extMark,
    intMark
}
export enum StudentTestFilter {
    studentTestId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    studentId,
    testId,
    testTypeId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    studentTestStatusId,
    isReleasedToParent,
    totalMark,
    totalMaxMark,
    grade,
    markSheetFile
}
export enum TestDetailFilter {
    testDetailId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    academicYearId,
    testId,
    subjectId,
    name,
    testDetailValueTypeId,
    maxMark,
    category,
    remarks,
    displayOrder,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    minMark,
    sectionId
}
export enum TestFilter {
    testId,
    clientId,
    organizationId,
    institutionId,
    parentTestId,
    name,
    testTypeId,
    courseId,
    batchId,
    academicYearId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TestTypeFilter {
    testTypeId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum NoticeBoardFilter {
    noticeboardId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    noticeDate,
    subjectId,
    description,
    attachmentPath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    expiryDate,
    receiverTypeId,
}
export enum AssignmentSubmissionFilter {
    assignmentSubmissionId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    assignmentId,
    studentId,
    assignmentDate,
    assignmentById,
    assignmentSubmissionStatusId,
    submittedDate,
    message,
    attachmentPath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    rejectReason,
    approveReason,
}

export enum TeacherCommentStudentFilter {
    teacherCommentStudentId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    teacherCommentId,
    studentId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}

export enum EmpTaskFilter {
    empTaskId,
    empTaskTypeId,
    taskDate,
    clientId,
    organizationId,
    institutionId,
    academicYearId,
    taskAssignStaffId,
    taskDescription,
    expectDate,
    actualCompleteDate,
    lessonName,
    totalHours,
    teacherComments,
    attachment,
    statusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}