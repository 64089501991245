import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'fx-core';
import { StudentAttendanceService } from '../../services/studentattendance.service';

@Component({
  selector: 'portlet-irregular-student',
  templateUrl: './portlet-irregular-student.component.html',
  styleUrls: ['./portlet-irregular-student.component.scss']
})
export class PortletIrregularStudentComponent implements OnInit {

  irregularStudents: any[] = [];

  constructor(protected utl: UtilService, private translate: TranslateService,
    private studentAttendanceService: StudentAttendanceService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {    
    const inputData = {

    };
    this.studentAttendanceService.GetIrregularStudents(inputData).then(res => this.getDataCallback(res));
  }

  getDataCallback(res) {
    this.irregularStudents = res.data;
  }
  
}
