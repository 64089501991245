import { Injectable } from "@angular/core";
import { UtilService } from "fx-core";

@Injectable()
export class LibraryService {
  constructor(public utl: UtilService) {}

  getLibraryInchargeMaps(inputData) {
    var options: any = {
      action: "library/libraryInchargeMap/getLibraryInchargeMaps",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getStudentDropDownDetails(inputData) {
    var options: any = {
      action: "student/student/getStudents",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getStaffDropdown(inputData) {
    var options: any = {
      action: "hrmemp/employee/getEmployees",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  addLibraryIncharge(inputData) {
    var options: any = {
      action: "library/libraryInchargeMap/addLibraryIncharge",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  delLibraryIncharge(inputData) {
    var options: any = {
      action: "library/libraryInchargeMap/deleteLibraryInchargeMap",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getBookDetails(inputData) {
    var options: any = {
      action: "library/bookLedger/getBookDetailsWithTransaction",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getStudentDetails(inputData) {
    var options: any = {
      action: "student/student/getStudentBySearch",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getStaffDetails(inputData) {
    var options: any = {
      action: "hrmemp/employee/getEmployeeBySearch",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getPendingIssuesByType(inputData) {
    var options: any = {
      action: "library/bookLedger/getPendingIssuesByType",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  issueBook(inputData) {
    var options: any = {
      action: "library/bookLedger/issueBook",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getBookLedgerDetailsById(inputData) {
    var options: any = {
      action: "library/bookLedger/getBookLedgerDetailsById",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  receiveBook(inputData) {
    var options: any = {
      action: "library/bookLedger/receiveBook",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getPresentDayBookSummary(inputData) {
    var options: any = {
      action: "library/bookLedger/getPresentDayBookSummary",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getExpiredTransaction(inputData) {
    var options: any = {
      action: "library/bookLedger/getExpiredTransactionFromPresentDate",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getPresentDateIssuedBooks(inputData) {
    var options: any = {
      action: "library/bookLedger/getPresentDateIssuedBooks",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getPresentDateReceivedBooks(inputData) {
    var options: any = {
      action: "library/bookLedger/getPresentDateReceivedBooks",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getPresentDateNewBooks(inputData) {
    var options: any = {
      action: "library/bookLedger/getPresentDateNewBooks",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getPresentDateDamagedBooks(inputData) {
    var options: any = {
      action: "library/bookLedger/getPresentDateDamagedBooks",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }

  getBookInfoById(inputData) {
    var options: any = {
      action: "library/book/getBookInfoById",
      type: "post",
      data: inputData,
    };

    return this.utl.http.doAction(options);
  }
}
