import { Component, OnInit, Input } from "@angular/core";
import { CommonPortletService } from "../services/commonportlet.service";
import {
  SectionFilter,
  BatchFilter,
  DistrictFilter,
  CityFilter,
  StateFilter,
  StudentFilter,
  CourseFilter
} from "../../application/enums";
import { UtilService, AppConfig } from "fx-core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ProjectUtil } from "../../application/services/project-util";
import * as moment from 'moment';

@Component({
  selector: "shared-student-personal",
  templateUrl: "./student-personal.component.html",
  styleUrls: ["./student-personal.component.scss"],
})
export class StudentPersonalComponent implements OnInit {
  @Input() contextData: any;
  @Input() modalData: any;
  @Input() routeData: any;
  lookup: any;
  states: any[] = [];
  cities: any[] = [];
  dists: any[] = [];
  batches: any[] = [];
  Section: any[] = [];
  toggleClass: boolean = false;
  AdmissionCategory: any[] = [];
  toggleClass2: boolean = false;
  toggleClass3: boolean = false;
  studentForm: any = {
    titlestatusUpdate: -1,
    admissiontypeId: -1,
    genderId: -1,
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    dob: "",
    admissionNo: "",
    admissionCategeoryId: -1,
    nationalityId: -1,
    aadhaarNo: "",
    address: "",
    imagePath: "",
    countryId: -1,
    stateId: -1,
    districtId: -1,
    cityId: -1,
    admissionYearId: -1,
    academicYearId: this.projectUtil.getCurrentAcademicYear(),
    courseId: -1,
    sectionId: -1,
    studentEnquiryStatusId: 1,
    fatherName: "",
    fatherMobileNo: "",
    fatherEmail: "",
    isActive: true,
    status: 1,
    batchId: -1,
    feeCategoryId: -1,
    religionId: -1,
    communityId: -1,
    caste: "",
    studentTypeId: -1,
    otherInfo: "",
    semesterId: -1,
    bloodGroupId: -1,
    guardianEmail: "",
    guardianMobileNo: "",
    guardianName: "",
    isHostel: false,
    isTransport: false,
    motherEmail: "",
    motherMobileNo: "",
    motherName: "",
    parentBloodGroupId: -1,
    parentEducation: "",
    parentPortalAccess: false,
    parentProfession: "",
    studentIdentifier: "",
    studentPortalAccess: true,
    studentStatusId: 1,
    enrollmentDate: moment().format('YYYY-MM-DD'),
    profileImage: "",
    admittedcourseId: -1,
    emisno: "",
    remarks: "",
    completedDate: null,
  };
  fileToUpload: File | null = null;
  fileName: any;
  isAdmissionForm: boolean = false;
  statusUpdate: boolean = true;
  constructor(
    private projectUtil: ProjectUtil,
    private api: CommonPortletService,
    protected utl: UtilService,
    public router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.getLookUpData();
    this.getAdmissionCategory()
    let routeData = this.utl.nav.peek();
    if (routeData && routeData.targetData && routeData.targetData.id) {
      if (routeData.targetData.studentEnquiryStatusId) {
        this.isAdmissionForm = true;
        this.studentForm = routeData.targetData;
        if (this.studentForm.countryId) {
          this.getStatesByCountry(this.studentForm.stateId);
        }
        if (this.studentForm.courseId) {
          this.getBatches(this.studentForm.batchId);
        }
        if (this.studentForm.stateId) {
          this.getDistByState(this.studentForm.districtId);
        }
        if (this.studentForm.districtId) {
          this.getCitiesByState(this.studentForm.cityId);
        }
      } else {
        this.getStudent(routeData.targetData.id);
      }
    }
  }

  getAdmissionCategory() {
    const params = {
      filters: [ { key:6, value: 1}, { key: 1, value: -1 },
        { key: 2, value: -1 },
        { key: 3, value: -1 },
      ],
      pageContext: {pageNumber: 1,pageSize: 100}
    }
    this.api.getData(params, "configuration/admissioncategory/getAdmissionCategorys").then(
      (data) => {
        this.AdmissionCategory = data.data;
      },
      (error) => {
        console.log(error);
      }
    );

  }

  getStudent(id) {
    // const body = {
    //   id: id,
    // };

    const inputData: any = {
      filters: [
        {
          key: StudentFilter.studentId,
          value: id,
        }
      ],
      include: [
        { key: "studentFee" },
      ]
    };
    this.api.saveData(inputData, "student/student/getStudents").then(
      (stud) => {
        const { data } = stud;

        this.studentForm = data[0];
        let dueAmount = 0;
        
        if(this.studentForm.studentFees){
          this.studentForm.studentFees.forEach((fee:any) =>{
            dueAmount = dueAmount + fee.dueAmount
          })
        }
        
        if(this.studentForm.admissionCategeoryId){
          this.getCoursesData()
        }

        if (parseInt(this.studentForm.studentStatusId) === 5) {
          this.statusUpdate = false;
        } else if(parseInt(this.studentForm.studentStatusId) != 5 && dueAmount === 0 && this.studentForm.studentFees.length > 0){
          this.statusUpdate = true;
        }else {
          this.statusUpdate = false;
        }
        if (this.studentForm.countryId) {
          this.getStatesByCountry(this.studentForm.stateId);
        }
        if (this.studentForm.courseId) {
          this.getBatches(this.studentForm.batchId);
        }
        if (this.studentForm.stateId) {
          this.getDistByState(this.studentForm.districtId);
        }
        if (this.studentForm.districtId) {
          this.getCitiesByState(this.studentForm.cityId);
        }
      },
      (err) => {}
    );
  }

  getLookUpData() {
    const lookups = [
      { key: "Title", default: true, request: { filters: [] } },
      { key: "Gender", default: true, request: { filters: [] } },
      {
        key: "AdmissionCategory",
        default: true,
        request: {
          filters: [
            { key: 1, value: -1 },
            { key: 2, value: -1 },
            { key: 3, value: -1 },
          ],
        },
      },
      {
        key: "Nationality",
        default: true,
        request: {
          filters: [
            { key: 1, value: -1 },
            { key: 2, value: -1 },
            { key: 3, value: -1 },
          ],
        },
      },
      { key: "Religion", default: true, request: { filters: [] } },
      { key: "Community", default: true, request: { filters: [] } },
      { key: "StudentType", default: true, request: { filters: [] } },
      { key: "Country", default: true, request: { filters: [] } },
      { key: "AcademicYear", default: true, request: { filters: [] } },
      { key: "StudentStatus", default: true, request: { filters: [] } },
      { key: "FeeCategory", default: true, request: { filters: [] } },
      { key: "BloodGroup", default: true, request: { filters: [] } },
      { key: "Semester", default: true, request: { filters: [] } },
      { key: "StudentStatus", default: true, request: { filters: [] } },
    ];

    this.api.getData(lookups, "Options/GetLookupOptions").then(
      (lookups) => {
        const { data } = lookups;
        this.lookup = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCoursesData() {
    const lookups = [      {
        key: "Course",
        default: true,
        request: {
          filters: [
            { key: CourseFilter.clientId, value: -1 },
            { key: CourseFilter.organizationId, value: -1 },
            { key: CourseFilter.institutionId, value: -1 },
            { key: CourseFilter.admissionCategeoryId, value: this.studentForm.admissionCategeoryId },
          ],
        },
      },
    ];

    this.api.getData(lookups, "Options/GetLookupOptions").then(
      (lookups) => {
        const { data } = lookups;
        this.lookup.Course = data.Course;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getStatesByCountry(state = "") {
    this.states = [];
    // this.studentForm.stateId = -1;
    const lookups = [
      {
        key: "State",
        default: true,
        request: {
          filters: [
            { key: StateFilter.countryId, value: this.studentForm.countryId },
          ],
        },
      },
    ];
    if (this.studentForm.countryId) {
      this.api.getData(lookups, "Options/GetLookupOptions").then(
        (lookups) => {
          const { data } = lookups;
          this.states = data.State;
          if (state) {
            this.studentForm.stateId = state;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getDistByState(districtId = "") {
    this.dists = [];
    this.studentForm.districtId = -1;
    const lookups = [
      {
        key: "District",
        default: true,
        request: {
          filters: [
            { key: DistrictFilter.stateId, value: this.studentForm.stateId },
          ],
        },
      },
    ];
    if (this.studentForm.stateId) {
      this.api.getData(lookups, "Options/GetLookupOptions").then(
        (lookups) => {
          const { data } = lookups;
          this.dists = data.District;
          if (districtId) {
            this.studentForm.districtId = districtId;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getCitiesByState(cityId = "") {
    this.cities = [];
    this.studentForm.cityId = -1;
    const lookups = [
      {
        key: "City",
        default: true,
        request: {
          filters: [
            { key: CityFilter.districtId, value: this.studentForm.districtId },
          ],
        },
      },
    ];
    if (this.studentForm.districtId) {
      this.api.getData(lookups, "Options/GetLookupOptions").then(
        (lookups) => {
          const { data } = lookups;
          this.cities = data.City;
          if (cityId) {
            this.studentForm.cityId = cityId;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getBatches(batchId = "") {
    this.batches = [];
    this.studentForm.batchId = -1;

    const lookups = [
      {
        key: "Batch",
        default: true,
        request: {
          filters: [
            { key: BatchFilter.courseId, value: this.studentForm.courseId },
          ],
        },
      },
    ];
    if (this.studentForm.courseId) {
      this.api.getData(lookups, "Options/GetLookupOptions").then(
        (lookups) => {
          const { data } = lookups;
          this.batches = data.Batch;

          if (batchId) {
            this.studentForm.batchId = batchId;
            this.getSems(this.studentForm.sectionId);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getSems(sectionId = "") {
    this.Section = [];
    this.studentForm.sectionId = -1;
    const lookups = [
      {
        key: "Section",
        default: true,
        request: {
          filters: [
            { key: SectionFilter.batchId, value: this.studentForm.batchId },
          ],
        },
      },
    ];
    if (this.studentForm.batchId) {
      this.api.getData(lookups, "Options/GetLookupOptions").then(
        (lookups) => {
          const { data } = lookups;
          this.Section = data.Section;
          if (sectionId) {
            this.studentForm.sectionId = sectionId;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  computeFileName(filePath) {
    let fileName = "";
    if (filePath) {
      if (filePath.lastIndexOf("\\") > -1) {
        fileName = filePath.substring(filePath.lastIndexOf("\\") + 1);
      } else if (filePath.lastIndexOf("/") > -1) {
        fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
      } else {
        if (filePath.lastIndexOf(".") > -1) {
          let fileExtension = filePath.substring(filePath.lastIndexOf(".") + 1);
          fileName = `download.${fileExtension}`;
        } else {
          fileName = "download";
        }
      }
      this.fileName = fileName;
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  validateAndSubmit() {
    if (!this.studentForm.firstName) {
      this.utl.alert.showErrorMsg("Firstname is required!");
      return;
    }

    if (!this.studentForm.lastName) {
      this.utl.alert.showErrorMsg("Lastname is required!");
      return;
    }

    // if (
    //   this.studentForm.mobileNo &&
    //   (this.studentForm.mobileNo.length != 10 ||
    //     isNaN(this.studentForm.mobileNo) === true)
    // ) {
    //   this.utl.alert.showErrorMsg(
    //     "Mobile No should contains numeric and 10 digits!"
    //   );
    //   return;
    // }

    if (!this.studentForm.dob) {
      this.utl.alert.showErrorMsg("DOB is required!");
      return;
    }

    if (!this.studentForm.countryId && this.studentForm.countryId <= 0) {
      this.utl.alert.showErrorMsg("Country is required!");
      return;
    }

    if (!this.studentForm.studentIdentifier) {
      this.studentForm.studentIdentifier =
        "APX" +
        this.studentForm.firstName[0].toUpperCase() +
        this.studentForm.lastName[0].toUpperCase() +
        "-" +
        Date.now();
    }

    if (!this.studentForm.studentIdentifier) {
      // auto generate
      this.utl.alert.showErrorMsg("Student identifier is required!");
      return;
    }

    if (!this.studentForm.academicYearId) {
      this.utl.alert.showErrorMsg("Academic year is required!");
      return;
    }

    if (!this.studentForm.courseId && this.studentForm.courseId <= 0) {
      this.utl.alert.showErrorMsg("Course is required!");
      return;
    }

    if (
      !this.studentForm.admissionYearId &&
      this.studentForm.admissionYearId <= 0
    ) {
      this.utl.alert.showErrorMsg("Admission year is required!");
      return;
    }

    if (!this.studentForm.batchId && this.studentForm.batchId <= 0) {
      this.utl.alert.showErrorMsg("Year is required!");
      return;
    }
    // if (!this.studentForm.sectionId && this.studentForm.sectionId <= 0) {
    //   this.utl.alert.showErrorMsg("Batches is required!");
    //   return;
    // }
    if (
      !this.studentForm.feeCategoryId &&
      this.studentForm.feeCategoryId <= 0
    ) {
      this.utl.alert.showErrorMsg("Fee category is required!");
      return;
    }
    // if (!this.studentForm.semesterId && this.studentForm.semesterId <= 0) {
    //   this.utl.alert.showErrorMsg("Academic batch is required!");
    //   return;
    // }

    if (!this.studentForm.fatherName) {
      this.utl.alert.showErrorMsg("Father name is required!");
      return;
    }

    // if (!this.studentForm.motherName) {
    //   this.utl.alert.showErrorMsg('Mother name is required!');
    //   return;
    // }

    if (!this.studentForm.mobileNo) {
      this.utl.alert.showErrorMsg("student mobile is required!");
      return;
    }

    // if (
    //   this.studentForm.fatherMobileNo &&
    //   (this.studentForm.fatherMobileNo.length != 10 ||
    //     isNaN(this.studentForm.fatherMobileNo) === true)
    // ) {
    //   this.utl.alert.showErrorMsg(
    //     "Mobile No should contains numeric and 10 digits!"
    //   );
    //   return;
    // }

    // if (this.studentForm.guardianMobileNo && (this.studentForm.guardianMobileNo.length != 10 || isNaN(this.studentForm.guardianMobileNo) === true)) {
    //   this.utl.alert.showErrorMsg('Mobile No should contains numeric and 10 digits!');
    //   return;
    // }

    // if (this.studentForm.motherMobileNo && (this.studentForm.motherMobileNo.length != 10 || isNaN(this.studentForm.motherMobileNo) === true)) {
    //   this.utl.alert.showErrorMsg('Mobile No should contains numeric and 10 digits!');
    //   return;
    // }

    this.saveOrUpdate();
  }

  back() {
    if (this.studentForm.id) {
      this.utl.nav.back();
    } else {
      this.router.navigate(["/student/students"]);
    }
  }

  completeCourse() {
    this.studentForm.studentStatusId = 5;
    this.studentForm.completedDate = new Date();
    this.validateAndSubmit();
  }

  createCouseFee(studentData: any) {
    const courseUrl = "configuration/course/getCourseById";

    this.api
      .saveData({ id: studentData.courseId }, courseUrl)
      .then((data: any) => {
        console.log(data);
        const body = {
          studentId: studentData.id,
          academicYearId: studentData.academicYearId,
          courseId: studentData.courseId,
          batchId: studentData.batchId,
          feeId: -1,
          amount: data.data.form_charge,
          netAmount: data.data.form_charge,
          feeServiceId: 3,
          feeGroupId: 2,
        };
        const url = "student/studentFee/create-student-course-fee";

        this.api.saveData({ data: body }, url).then((item: any) => {
          let inputData = {
            targetData: { studentKey: studentData.studentIdentifier },
            targetRoute: "/fee/feecollect",
          };
          this.utl.nav.navigate(inputData);
        });
      });
  }

  saveOrUpdate() {
    let saveWithFile = AppConfig.API_BASE_PATH + "student/student/saveStudent";

    let saveWithOutFile = "student/student/AddStudent";
    if (this.isAdmissionForm === true) {
      delete this.studentForm.id;
    }

    let body: any = { data: this.studentForm };

    if (this.studentForm.id && this.isAdmissionForm === false) {
      saveWithOutFile = "student/student/UpdateStudent";
    }

    if (this.fileToUpload) {
      const formData: FormData = new FormData();
      formData.append("file", this.fileToUpload, this.fileToUpload.name);
      for (var key in this.studentForm) {
        formData.append(key, this.studentForm[key]);
      }
      let headersObj = new HttpHeaders();
      let sessionId = this.utl.session.get("id_token");
      if (sessionId) {
        let strSessionId = AppConfig.AUTH_TYPE
          ? AppConfig.AUTH_TYPE + " " + sessionId
          : sessionId;
        headersObj = headersObj.append("Authorization", strSessionId);
      }

      this.http.post(saveWithFile, formData, { headers: headersObj }).subscribe(
        (data: any) => {
          if (saveWithOutFile === "student/student/AddStudent") {
            this.createCouseFee(data.data);
          }else {
            this.getStudent(this.studentForm.id);
          }

          this.utl.alert.showSuccessMsg();
        },
        (err) => {
          const { error } = err;
          if (error) {
            let msg = "Student details failed to save";
            if (error.error && error.error.message) {
              msg = error.error.message;
            }
            this.utl.alert.showErrorMsg(msg);
          } else {
            this.utl.alert.showErrorMsg("Student details failed to save");
          }
        }
      );
    } else {
      this.api.saveData(body, saveWithOutFile).then(
        (resData: any) => {
          if (saveWithOutFile === "student/student/AddStudent") {
            this.createCouseFee(resData.data);
          }else {
            this.getStudent(this.studentForm.id);
          }

          this.utl.alert.showSuccessMsg();
        },
        (err) => {
          const { error } = err;
          if (error) {
            let msg = "Student details failed to save";
            if (error.error && error.error.message) {
              msg = error.error.message;
            }
            this.utl.alert.showErrorMsg(msg);
          } else {
            this.utl.alert.showErrorMsg("Student details failed to save");
          }
        }
      );
    }
  }
}
