import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'fx-core';
import { StudentAttendanceService } from '../../services/studentattendance.service';
import * as Highcharts from "highcharts";
import * as hc3d from "highcharts/highcharts-3d";
hc3d(Highcharts);

@Component({
  selector: 'portlet-chart-classwise-attendance',
  templateUrl: './portlet-chart-classwise-attendance.component.html',
  styleUrls: ['./portlet-chart-classwise-attendance.component.scss']
})
export class PortletChartClasswiseAttendanceComponent implements OnInit {

  Highcharts = Highcharts;
  coursewiseAttendances: any[] = [];

  constructor(protected utl: UtilService, private translate: TranslateService,
    private studentAttendanceService: StudentAttendanceService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {    
    const inputData = {

    };
    this.studentAttendanceService.GetCourseWiseAttendanceReport(inputData).then(res => this.getDataCallback(res));
  }

  drawchart() {
    Highcharts.chart('coursewise-container', {
      credits: {
                enabled: false
            },
      chart: {
          type: 'pie',
          options3d: {
              enabled: true,
              alpha: 45
          }
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      plotOptions: {
          pie: {
              innerSize: 100,
              depth: 45
          }
      },
      series: [{
          name: 'Student Count',
          data: this.coursewiseAttendances
      }]
    });
  }

  getDataCallback(res) {
    this.coursewiseAttendances = [];
    const result = res.data;
    result.forEach(element => {
        this.coursewiseAttendances.push([element.courseName, element.presentStudentCount]);
    });
    this.drawchart();
  }
  
}
