import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class EmpDocumentService {
    constructor(public utl: UtilService) {
    }

    getEmpDocuments(inputData) {
        var options: any = {
            action: 'hrmemp/empdocument/getEmpDocuments',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    deleteEmpDocument(inputData) {
        var options: any = {
            action: 'hrmemp/empdocument/deleteEmpDocument',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }
}