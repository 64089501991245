import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class StudentService {
    constructor(public utl: UtilService) {

    }

    getStudentDetails(inputData) {
        var options: any = {
            action: 'student/student/getStudentBySearch',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getStudentDetailsByIds(inputData) {
        var options: any = {
            action: 'student/student/getStudents',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getAllInsByOrg(inputData) {
        var options: any = {
            action: 'admin/institution/getInstitutions',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }


}