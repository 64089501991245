import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class HostelMgmtService {
    constructor(public utl: UtilService) {

    }

    getRoomDetails(inputData) {
        var options: any = {
            action: 'hostelmgmt/roomMaster/getRoomMasterById',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getRoomBedDetails(inputData) {
        var options: any = {
            action: 'hostelmgmt/roombed/getRoomBeds',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getStudentRoomDetails(inputData) {
        var options: any = {
            action: 'hostelmgmt/studentroom/getStudentRooms',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }


    getStudentFeesDetails(inputData) {
        var options: any = {
            action: 'student/studentFee/getStudentFeesForFeeCollect',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }


    addStudentRoomDetails(inputData) {
        var options: any = {
            action: 'hostelmgmt/studentroom/addStudentRoom',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    updateStudentRoomDetails(inputData) {
        var options: any = {
            action: 'hostelmgmt/studentroom/updateStudentRoom',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    syncFees(inputData) {
        var options: any = {
            action: 'hostelmgmt/studentroom/syncRoomFees',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }
    

    addFeesServices(inputData) {
        var options: any = {
            action: 'student/studentfee/addStudentFeeDetail',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    vacantStudentRoom(inputData) {
        var options: any = {
            action: 'hostelmgmt/student-room/vacantStudentRoom',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    saveEbReading(inputData) {
        var options: any = {
            action: 'hostelmgmt/room-eb-reading/saveRoomEbReading',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getEbReading(inputData) {
        var options: any = {
            action: 'hostelmgmt/roomEbReading/getRoomEbReadings',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

}