import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class CommonPortletService {
    constructor(public utl: UtilService) {
    }

    GetEvents(inputData) {
        var options: any = {
            action: 'admin/event/getEvents',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getData(inputData, URL) {
        var options: any = {
            action: URL,
            type: 'post',
            data: inputData
        };
        return this.utl.http.doAction(options);
    }

    saveData(inputData, URL) {
        var options: any = {
            action: URL,
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }
}