import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig, UtilService } from 'fx-core';
import { UserPreferenceService } from '../../services/userpreference.service';

@Component({
  selector: 'app-apex-student-login',
  templateUrl: './apex-student-login.component.html',
  styleUrls: ['./apex-student-login.component.scss']
})
export class ApexStudentLoginComponent implements OnInit {

    valForm: FormGroup;
    user = {
        UserName: '',
        Password: ''
    };

    currentcontext: any = {
        version: '',
        landingRoute: '',
        tk: '',
        autoLoginLandingRoute: ''
    }

    constructor(public settings: SettingsService, fb: FormBuilder, public router: Router, public utl: UtilService,
            private userPrefService: UserPreferenceService, public route: ActivatedRoute) {

        this.valForm = fb.group({
            'username': [null, Validators.compose([Validators.required])],
            'password': [null, Validators.required]
        });

    }

    ngOnInit() {
        this.currentcontext.version = AppConfig.WEB_VERSION;
        this.currentcontext.tk = this.route.snapshot.queryParamMap.get("tk");
        this.currentcontext.autoLoginLandingRoute = this.route.snapshot.queryParamMap.get("lp");

        if (this.currentcontext.tk) {
            this.authenticateToken();
        } 
    }

    authenticateToken() {
        this.utl.session.set('id_token', this.currentcontext.tk);
        
        let options: any = {
            action: 'admin/user/getUserForAutoLogin',
            data: {},
            type: 'post'
        };
        this.utl.http.doAction(options).then(data => this.signInCallback(data));
    }

    signIn() {
        if (!this.user.UserName || !this.user.Password) {
            return;
        }
        var inputData = { userName: this.user.UserName, password: this.user.Password };
        var options: any = {
            action: 'idp/login',
            data: inputData,
            type: 'post'
        };

        this.utl.http.doAction(options).then(data => this.signInCallback(data));
    }

    signInCallback(res) {
        if(res.data && res.data.user && res.data.user.group  && (res.data.user.group.code).toLowerCase() === 'student'){
            if(!this.currentcontext.tk) {
                this.utl.session.set('id_token', res.data.token);
            }
            var authToken = res.data;
            this.utl.session.setObject('authtoken', authToken);
            this.computeRoles(res.data);
            this.initiateUserSession();
        } else {
            this.utl.alert.showErrorMsg('Only students allowed to login!')
        }
        
    }

    initiateUserSession() {
        let inputData = {};
        var options: any = {
            action: 'student/student/initiateUserSession',
            data: inputData,
            type: 'post'
        };

        this.utl.http.doAction(options).then(data => this.initateUserSessionCallback(data));
    }
    initateUserSessionCallback(res) {
        this.userPrefService.computePreferences(res.data.preferences);
        this.userPrefService.computeSessionVariables(res.data);
        this.computeRolePrivileges(res.data.rolePrivileges);

        let authToken = this.utl.session.getObject('authtoken');
        var landingRoute = '/std-login';
        if (authToken.user.groupId == 1) { //1 - superadmin
            landingRoute = "/superadmin/clients";
        }  if (authToken.user.groupId == 164) { // 164 - partners
            landingRoute = "/partners/institutions";
        } else {
            if (!this.currentcontext.landingRoute && authToken.user.group.code == 'admin') {
                landingRoute = '/admin/roles';
            }
            else if (!this.currentcontext.landingRoute) {
                this.utl.alert.showErrorMsg('Landing page not configured for roles!')
            } else {
                landingRoute = this.currentcontext.landingRoute;
            }
        }

        landingRoute = this.currentcontext.autoLoginLandingRoute ? this.currentcontext.autoLoginLandingRoute : landingRoute;
        this.utl.session.set('landingroute', landingRoute);
        this.router.navigate([landingRoute]);
    }

    computeRoles(res) {

        if (res.user && res.user.group && res.user.group.roles && res.user.group.roles.length > 0) {
            var roles = res.user.group.roles;
            if (roles && roles.length > 0) {
                for (let idx in roles) {
                    let item = roles[idx];
                    if (item.landingControl) {
                        this.currentcontext.landingRoute = item.landingControl.sRef;
                        break;
                    }
                }
            }

        }
    }

    computeRolePrivileges(rolePrivileges) {
        var rolePrivMap = {};
        for (var idx in rolePrivileges) {
            var priv = rolePrivileges[idx];
            var privKey = priv.moduleCode + "." + priv.privilegeCode;
            rolePrivMap[privKey] = priv.isEnabled;
        }
        console.log('rolePrivMap=',rolePrivMap);
        this.utl.session.setObject('roleprivilegemap', rolePrivMap);
    }

    forgotpassword() {
        this.utl.nav.navigate({ targetRoute: '/forgotpassword' });
    }
}
