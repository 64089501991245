export enum BloomTaxonomyFilter {
    bloomTaxonomyId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum PeriodTypeFilter {
    periodTypeId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SyllabusPlanFilter {
    syllabusPlanId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    subjectId,
    periodTypeId,
    bloomtaxonomyId,
    colorCode,
    staffId,
    chapterName,
    lessonNo,
    startDate,
    endDate,
    noofPeriods,
    hoursRequired,
    lessonName,
    topictobeCovered,
    pageNo,
    teachercomments,
    lessonNotes,
    attachment,
    statusId,
    actualcompletedate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SyllabusPlanDetailFilter {
    syllabusPlanDetailId,
    syllabusPlanId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    academicYearId,
    subjectId,
    periodTypeId,
    bloomtaxonomyId,
    staffId,
    chapterName,
    lessonNo,
    planedDate,
    completeddate,
    period,
    hoursRequired,
    lessonName,
    topictobeCovered,
    pageNo,
    teachercomments,
    lessonNotes,
    attachment,
    statusId,
    actualcompletedate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
