import { Injectable } from '@angular/core';
import { BookInfoComponent } from '../../library/book-info/book-info.component';

@Injectable({
  providedIn: 'root',
})
export class ComponentResolverService {
  constructor() {
  }

  componentMap = {
    BookInfo: BookInfoComponent
  };

  resolveComponent(componentName: any) {
    return this.componentMap[componentName];
  }
}
