import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class SyllabusService {
    constructor(public utl: UtilService) {
    }

    GetSyllabusOneDay(inputData) {
        var options: any = {
            action: 'syllabus/syllabusPlanDetail/getSyllabusPlanDetails',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    postData(inputData, Url) {
        var options: any = {
            action: Url,
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }


}