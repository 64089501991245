export enum BoardingPointPassengerFilter {
    boardingPointPassengerId,
    clientId,
    organizationId,
    institutionId,
    routeId,
    boardingPointId,
    passengerTypeId,
    studentId,
    empId,
    seatNo,
    comments,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum BoardingPointFilter {
    boardingPointId,
    clientId,
    organizationId,
    institutionId,
    name,
    address,
    area,
    landmark,
    latitude,
    longitude,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    serviceTypeId,
    amount,
}
export enum BusFilter {
    busId,
    clientId,
    organizationId,
    institutionId,
    name,
    driverId,
    driverPhone,
    routeId,
    vehicleNo,
    attenderId,
    attenderPhone,
    noOfSeats,
    minStudents,
    maxStudents,
    inchargeId,
    imagePath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RouteBoardingPointMapFilter {
    routeBoardingPointMapId,
    clientId,
    organizationId,
    institutionId,
    routeId,
    boardingPointId,
    boardingSequenceNo,
    pickupEta,
    pickupEtd,
    dropEta,
    dropEtd,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RouteFilter {
    routeId,
    clientId,
    organizationId,
    institutionId,
    name,
    sourceBoardingPointId,
    destBoardingPointId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TripAttendanceFilter {
    tripAttendanceId,
    clientId,
    organizationId,
    institutionId,
    tripId,
    boardingPointId,
    passengerTypeId,
    empId,
    studentId,
    attendanceStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TripDetailFilter {
    tripDetailId,
    clientId,
    organizationId,
    institutionId,
    tripId,
    boardingPointId,
    ata,
    atd,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TripFilter {
    tripId,
    clientId,
    organizationId,
    institutionId,
    busId,
    routeId,
    tripTypeId,
    driverId,
    attenderId,
    tripDate,
    startTime,
    endTime,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
