import { Component, Input } from '@angular/core';
import { ProjectUtil } from '../../application/services/project-util';
import * as moment from 'moment';

@Component({
  selector: 'date-display-format',
  templateUrl: './date-display-format.component.html',
  styleUrls: ['./date-display-format.component.scss']
})

export class DateDisplayFormatComponent {

  private _dateval: any;

  @Input()
  set dateval(parentData: any) {
    this._dateval = parentData;
    this.formatDate();
  }
  get dateval(): any { return this._dateval; }

  dateDisplay: any;

  constructor(private projectUtil: ProjectUtil) { }

  ngOnInit() {
    if (this.dateval) {
      this.formatDate();
    }
  }

  formatDate() {
    const prefFormat = this.projectUtil.getPreferenceDateFormat();
    this.dateDisplay = moment(this.dateval).format(prefFormat);
  }
}
