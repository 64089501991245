import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { AppConfig, UtilService } from 'fx-core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'camera-component',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  
  @ViewChild("cameratpl") cameratpl: TemplateRef<any>;
  cameraMoalRef: BsModalRef;

  @Input() control: any;
  @Output() valueChanged = new EventEmitter<any>();

  public errors: WebcamInitError[] = [];
  // latest snapshot
  public webcamImage: WebcamImage = null;
  public finalWebcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  constructor(public utl: UtilService, private modalService: BsModalService) {
  }

  ngOnInit() {
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  //Camera modal related code starts
  returnCameraImage() {
    this.closeCameraModal();

    this.finalWebcamImage = this.webcamImage;
    //Emit image data to the form-component (parent using camera control)
    this.valueChanged.emit(this.webcamImage.imageAsBase64);
  }

  openCameraModal() {
    this.cameraMoalRef = this.modalService.show(this.cameratpl, { class: 'custom-modal-sm' });
  }

  closeCameraModal() {
    this.cameraMoalRef.hide();
  }

  //Camera modal related code ends
}
