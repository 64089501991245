import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class UserService {
    constructor(public utl: UtilService) {
    }

    GenerateOTP(inputData) {
        var options: any = {
            action: 'security/forgotpassword',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    VerifyOTP(inputData) {
        var options: any = {
            action: 'security/verifyotp',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    savePassword(inputData) {
        var options: any = {
            action: 'security/changepasswordusingotp',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }
    
}