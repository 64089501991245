import { Component, ViewChildren, QueryList, Input, ViewChild, TemplateRef } from '@angular/core';
import { OptionsService, UtilService, FileUploadComponent, MessageService } from 'fx-core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { EmpDocumentFilter } from '../../application/enums';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EmpDocumentService } from '../services/empdocument.service';

@Component({
    selector: 'emp-document-modal',
    templateUrl: 'emp-document-modal.component.html',
    styleUrls: ['emp-document-modal.component.scss']
})

export class EmpDocumentModalComponent {

   valForm: FormGroup;
    lookup: any = {};
    @Input() uploadFormModel: any;

    //File upload related code
    fileUploadConfig: any = {
        url: "hrmemp/empdocument/saveEmpDocumentViaUpload"
    };
    @ViewChildren(FileUploadComponent) fileUploadControls: QueryList<FileUploadComponent>;

    constructor(protected utl: UtilService, private optionsService: OptionsService,
            private messageService: MessageService,
            private modalRef: BsModalRef) {
    }

    ngOnInit() {
        this.initLookup();
    }

    initLookup() {
        const lookupConfig = {
            DocumentType: { default: true }
        };
        const inputData = {
            lookups: lookupConfig,
            filters: {}
        };
        const lookupInputData = {
            data: inputData,
            callback: this.processCallback(this.lookup)
        }

        this.optionsService.getData(lookupInputData);
    }

    processCallback(context) {
        return (res) => {
            for (const key in res) {
                context[key] = res[key];
            }
        };
    }

    saveItem() {
        if(this.fileUploadControls && this.fileUploadControls.length > 0
            && this.fileUploadControls.first.uploader.getNotUploadedItems().length > 0) {
                setTimeout(() => {
                    this.fileUploadControls.forEach((uploadControl) => {
                        if (uploadControl) {
                            uploadControl.uploadDocument();
                        }
                    });
                }, 100);
        }
        else {
            if(this.uploadFormModel.documentTypeId == -1) {
                this.utl.alert.showErrorMsg('Please select attachment type');
                return;
            }
            this.utl.alert.showErrorMsg('Please select document');
            return;
        }
    }

    uploadDocumentCallback(event) {
        this.utl.alert.showSuccessMsg();
        this.messageService.sendMessage("emp-document-added", {});
        this.modalRef.hide();
    }

    //File upload related code ends
}
