import { Injectable, TemplateRef } from "@angular/core";
import { UtilService, HttpService, SessionStorageService } from "fx-core";

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  private preferences: any = {};

  constructor(private http: HttpService, private session: SessionStorageService) {
  }

  computePreferences(preferencesList: any) {
    let preferences = [];
    if (preferencesList) {
      for (let idx in preferencesList) {
        let item = preferencesList[idx];
        this.postProcessPreference(item);
        preferences[item.preferenceKey] = item.preferenceValue;
      }
    }
    this.preferences = preferences;
  }

  postProcessPreference(pref) {
    if(pref.preferenceKey == 'currentAcademicYearId'){
      pref.preferenceValue = pref.preferenceValue ? parseInt(pref.preferenceValue) : pref.preferenceValue;
    }
  }

  getPreference(key: string): any {
    return this.preferences[key];
  }

  computeSessionVariables(data) {
    //this.utl.session.set('userdata', data);
    let sessionVariablesMap = {};
    
    if(data.sessionVariables) {
      for(let idx in data.sessionVariables) {
          let item = data.sessionVariables[idx];
          sessionVariablesMap[item.code] = item.text;
      }
    }
    if (data.preferences) {
      for (let idx in data.preferences) {
        let item = data.preferences[idx];
        this.postProcessPreference(item);
        sessionVariablesMap[item.preferenceKey] = item.preferenceValue;
      }
    }
    if(data.myDefaults) {
      sessionVariablesMap = Object.assign(sessionVariablesMap, data.myDefaults);
    }
    this.session.setSessionVariables(sessionVariablesMap);

    if(data.student) {
      this.session.setObject('studentObj', data.student);
    }

    if(data.baseURI) {
      this.session.setObject('baseURI', data.baseURI);
    }
  }

  //reset preferences 
  resetPreferences() {
    var options: any = {
      action: 'Options/InitiateUserSession',
      data: null,
      type: 'post'
    };

    this.http.doAction(options).then(data => this.resetPreferencesCallback(data));
  }
  resetPreferencesCallback(data) {
    this.computeSessionVariables(data.SessionVariables);
    this.computePreferences(data.UserPreferences);
  }
}