import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss']
})
export class VideoViewerComponent implements OnInit {
  @Input() url: any;

  constructor(private modalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.url)
  }

  closeModal(){
    this.modalRef.hide();
  }

}
