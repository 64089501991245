import { Component, Input, Output, EventEmitter, forwardRef, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { DialogService, UtilService } from 'fx-core';
import { Subscription } from 'rxjs';
import { ComponentResolverService } from '../services/component-resolver.service';

@Component({
  selector: 'page-component',
  templateUrl: './page-component.html',
  styleUrls: ['./page-component.scss'],
})

export class PageComponent {

  subscription: Subscription;
  pageConfig: any;
  constructor(public cmpResolver: ComponentResolverService, private modalService: BsModalService, 
          private diaglogService: DialogService, private router: Router, private utl: UtilService) {
     this.subscription = this.diaglogService.getMessage().subscribe(res => 
      {
          if(res && res.type == 'openModal') { 
            this.openModal(res);
          }
      }
  );
  }

  ngOnInit() {
    this.init();
  }

  init() {

    var inputParam = { url: this.router.url };
    var options: any = {
      action: "PageConfig/GetPageConfig",
      type: 'post',
      data: inputParam,
    };

    return this.utl.http.doAction(options).then(data => this.getPageConfigCallback(data));
  }
  getPageConfigCallback(res) {
    this.pageConfig = res.data;
  }

  openModal(res) {
    var action = res.data.action;
    var contextData = res.data.contextData;
    var field = contextData.colDef ? contextData.colDef.field : action.field;

    let cmp = this.cmpResolver.resolveComponent(action.route);
    let initialState: any = {
      inputData: { field: field, data: contextData.data, onSubscribe : res.data.onSubscribe },      
    };

    let modalCls = 'custom-modal-lg';
    if(action.modalConfig && action.modalConfig.size) {
      modalCls = 'custom-modal-' + action.modalConfig.size;
    }

    this.modalService.show(cmp, { class: modalCls, initialState });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
}
}
