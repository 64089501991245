import { Component, Input } from '@angular/core';
import { UtilService } from 'fx-core';
import * as moment from 'moment';

@Component({
  selector: 'date-time-display-localzone',
  templateUrl: './date-time-display-localzone.html',
  styleUrls: ['./date-time-display-localzone.scss']
})

export class DateTimeDisplayLocalzoneComponent {
  
  private _datetimeval: any;

  @Input()
  set datetimeval(parentData: any) {
    this._datetimeval = parentData;
    this.parseDate();
  }
  get datetimeval(): any { return this._datetimeval; }

  @Input() displaytype: any;

  dateTimeDisplay: any;
  
  constructor(protected utl: UtilService) { }

  ngOnInit() {
    if (this.datetimeval) {
      this.parseDate();
    }
  }

  parseDate() {
    console.log("Type-"+this.displaytype);
    let localDateTime = this.getLocalDateTimeFromUTC(this.datetimeval);
    if(this.displaytype == 'time') {
      this.dateTimeDisplay = this.getTimeString(localDateTime);
    }
    else if(this.displaytype == 'date') {
      this.dateTimeDisplay = this.getDateString(localDateTime);
    }
    else if(this.displaytype == 'datetime') {
      this.dateTimeDisplay = this.getDateTimeString(localDateTime);
    }    
  }

  getLocalDateTimeFromUTC(inputDate) {
    var result = {};
    if(inputDate) {
        let temp = moment.utc(inputDate).local();
        result = temp.format("YYYY-MM-DD HH:mm:ss");
    }
    return result;
  }

  getTimeString(inputDate) {
    return moment(inputDate).format("HH:mm");
  }

  getDateString(inputDate) {
    return moment(inputDate).format("YYYY-MM-DD");
  }
  
  getDateTimeString(inputDate) {
    return moment(inputDate).format("DD/MMM/YYYY hh:mm a");
  }    
}
