import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'fx-core';
import { StudentAttendanceService } from '../../services/studentattendance.service';
import * as Highcharts from "highcharts";

@Component({
  selector: 'portlet-male-female-student-count',
  templateUrl: './portlet-male-female-student-count.html',
  styleUrls: ['./portlet-male-female-student-count.scss']
})
export class PortletMaleFemaleStudentCountComponent implements OnInit {

  Highcharts = Highcharts;
  studentCount: any = [];

  constructor(protected utl: UtilService, private translate: TranslateService,
    private studentAttendanceService: StudentAttendanceService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    const inputData = {

    };
    this.studentAttendanceService.GetMaleFemaleStudentCounts(inputData).then(res => this.getDataCallback(res));
  }

  getDataCallback(res) {
    let countDetails: any = [];
    let response = res.data;
    if(response) {
      countDetails.push(['Male', response.maleStudentCount]);
      countDetails.push(['Female', response.femaleStudentCount]);
    }
    this.studentCount = countDetails;
    this.drawchart();
  }

  drawchart() {
    Highcharts.chart('male-female-student-count', {
      credits: {
        enabled: false
      },
      chart: {
        type: 'pie',
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      plotOptions: {
        pie: {
          innerSize: '60%',
          depth: 45
        }
      },
      series: [{
        name: 'Student Count',
        data: this.studentCount
      }]
    });
  }

}
