import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FxCoreModule } from 'fx-core';
import { PageComponent } from './page/page-component';

@NgModule({
    imports: [
        FxCoreModule.forRoot()
    ],
    declarations: [
        PageComponent 
    ],
    exports: [
        PageComponent
    ],
    entryComponents: [PageComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PageModule { }