import { Component, OnInit } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CommonPortletService } from '../../services/commonportlet.service';


@Component({
  selector: 'app-portlet-calendar',
  templateUrl: './portlet-calendar.component.html',
  styleUrls: ['./portlet-calendar.component.scss']
})
export class PortletCalendarComponent implements OnInit {

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  actions: CalendarEventAction[] = [];
  activeDayIsOpen: boolean = true;

  constructor(public commonPortletService: CommonPortletService) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    var inputData = {
      filters: []
    };
    this.commonPortletService.GetEvents(inputData).then(res => this.getListCallback(res));
  }

  getListCallback(res) {
    let eventlist = res.data;
    let calendarEvents: CalendarEvent[] = [];
    eventlist.forEach(event => {
      let eventitem: CalendarEvent = {
        start:  new Date(event.startDate),
        end: new Date(event.endDate),
        title: event.eventSubject
      };      
      calendarEvents.push(eventitem);
    });
    this.events = calendarEvents;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

}
