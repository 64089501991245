export enum AssessmentQuestionFilter {
    assessmentQuestionId,
    assessmentId,
    questionId,
    displayorder,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum AssessmentResponseFilter {
    assessmentResponseId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    assessmentId,
    studentId,
    responseDate,
    responseNotes,
    reviewNotes,
    answeredCount,
    correctAnswerCount,
    resultValue,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    questionsCount,
    assessmentResponseStatusId,
    submittedDate,
    totalMark,
    isReleasedToStudent,
}
export enum AssessmentResponseDetailFilter {
    assessmentResponseDetailId,
    clientId,
    organizationId,
    institutionId,
    assessmentResponseId,
    assessementId,
    questionId,
    answerOptionId,
    answerText,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    answerAttachmentPath
}
export enum AssessmentResponseHeaderFilter {
    assessmentResponseHeaderId,
    clientId,
    organizationId,
    institutionId,
    assessmentResponseId,
    assessementId,
    questionId,
    questionComplexityId,
    questionCategoryId,
    mark,
    isAnswered,
    answeredOn,
    isCorrect,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    defaultMark,
}
export enum AssessmentFilter {
    assessmentId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    testName,
    assessmentDate,
    duration,
    gradingTypeId,
    passValue,
    description,
    instruction,
    partialMarking,
    mandateCompletion,
    enforceTimings,
    shuffleQuestions,
    answerAllQuestions,
    isMultipleSubmissionAllowed,
    publishResult,
    issueCertificate,
    disableCopyPaste,
    showResultId,
    onTimeExpiryId,
    imagePath,
    reviewerId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    academicYearId,
    startTime,
    assessmentStatusId
}
export enum QuestionAnswerFilter {
    questionAnswerId,
    clientId,
    organizationId,
    institutionId,
    questionId,
    questionCode,
    answerOptionId,
    helpText,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum QuestionCategoryFilter {
    questionCategoryId,
    clientId,
    organizationId,
    institutionId,
    categoryCode,
    categoryName,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum QuestionOptionFilter {
    questionOptionId,
    clientId,
    organizationId,
    institutionId,
    questionId,
    questionCode,
    optionText,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum QuestionFilter {
    questionId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    sectionId,
    subjectId,
    questionCode,
    questionName,
    description,
    questionComplexityId,
    questionCategoryId,
    knowledgeWeightage,
    defaultMark,
    penalty,
    maxTime,
    questionTypeId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    attachmentPath
}
