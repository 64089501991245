import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { AppConfig, UtilService } from 'fx-core';
import { UserPreferenceService } from '../../services/userpreference.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'imageview-component',
    templateUrl: './imageview.component.html',
    styleUrls: ['./imageview.component.scss']
})
export class ImageViewComponent implements OnInit {

    @Input() control: any;
    @Input() storageType: any;

    @Input() imageWidth: any;
    @Input() imageHeight: any;

    private _filePath: any;
    @Input()
    set filePath(parentData: any) {
        this._filePath = parentData;
        this.downloadFile();
    }
    get filePath(): any { return this._filePath; }

    fileName: any;
    currentContext : any = {
        imageData: null
    };

    constructor(public utl: UtilService, private http: HttpClient, private sanitizer : DomSanitizer) {
    }

    ngOnInit() {
        this.imageWidth = this.imageWidth || 100;
        this.imageHeight = this.imageHeight || 100;
    }

    //download file
    downloadFile() {
        let inputData = { url: this.filePath };
        let actionUrl = this.storageType == "public" ? "exports/getPublicFile" :  "exports/getFile";
        var options: any = {
            action: actionUrl,
            type: 'post',
            data: inputData,
            callback: this.computeImage(this.currentContext, this.sanitizer)
        };
        this.doDownload(options);
        //this.doDownload(options).then(data => this.downloadFileCallback(data));
    }

    computeImage(detail, sanitizer) {
        return (res) => {
            var reader = new FileReader();
            reader.readAsDataURL(res); 
            reader.onloadend = function() {
                 let base64Data: any = reader.result;  
                 base64Data = base64Data.replace('data:application/oct-steam;base64', 'data:image/jpeg;base64')  ;
                 detail.imageData = sanitizer.bypassSecurityTrustUrl(base64Data);  
            }
        };
    }

    doDownload(options): any {
		let url = AppConfig.API_BASE_PATH + options.action;
		let body = this.transformRequest(options.data);
		let headersObj = new HttpHeaders({ 'Content-Type': 'application/json' });

		let sessionId = this.utl.session.get("id_token");
		if (sessionId) {
			let strSessionId = AppConfig.AUTH_TYPE ? AppConfig.AUTH_TYPE + ' ' + sessionId : sessionId;
			headersObj = headersObj.append('Authorization', strSessionId);
		}

		if (options.type == 'post') {
			return this.http.post(url, body, { headers: headersObj, responseType: "blob" }).subscribe(
				response => { 
                    options.callback(response);
                    return true;
				},
				error => {
					console.log(error, options);
				}
			);
        }
        return 
    }
    
    transformRequest(data) {
		var strItem = JSON.stringify(data);
		return strItem;
		//return this.isObject(data) && String(data) !== '[object File]' ? this.param(data) : data;
	}
}
