import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class StudentAttendanceService {
    constructor(public utl: UtilService) {
    }

    GetTodaysReport(inputData) {
        var options: any = {
            action: 'student/studentattendance/GetTodaysAttendanceReport',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    GetCourseWiseAttendanceReport(inputData) {
        var options: any = {
            action: 'student/studentattendance/GetCourseWiseAttendanceReport',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    GetYearlyAttendanceSummary(inputData) {
        var options: any = {
            action: 'student/studentattendance/GetYearlyAttendanceSummary',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    GetIrregularStudents(inputData) {
        var options: any = {
            action: 'student/studentattendance/GetIrregularStudents',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    GetMaleFemaleStudentCounts(inputData) {
        var options: any = {
            action: 'student/student/getMaleFemaleStudentCount',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

}