import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConferenceService } from '../services/conference.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { timer, Subscription } from 'rxjs';

@Component({
    selector: 'app-guestconference',
    templateUrl: './guestconference.component.html',
    styleUrls: ['./guestconference.component.scss']
})
export class GuestConferenceComponent implements OnInit {

    conferenceUrl: any;
    conferenceId = -1;
    guestName = '';
    currentContext: any = {
        isTimerOn: false,
        joinClicked: false
    };
    timerSubscription: any;

    constructor(private route: ActivatedRoute, private conferenceService: ConferenceService, public sanitizer: DomSanitizer) {
        this.conferenceId = this.route.snapshot.queryParams.c;
    }

    ngOnInit() {

    }

    getGuestURL() {
        if (this.conferenceId > 0 && this.guestName.length > 0) {
            this.currentContext.joinClicked = true;
            let inputData = {
                conferenceId: this.conferenceId,
                guestName: this.guestName
            }
            this.conferenceService.getGuestJoinUrl(inputData).then(data => this.getGuestURLCallback(data));
        }
    }

    getGuestURLCallback(res) {
        let url = res.data;
        if (url == null) {
            // meeting not started
            if (!this.currentContext.isTimerOn) {
                this.initTimer();
            }
        } else {
            if (this.currentContext.isTimerOn) {
                this.unSubscribeTimer();
            }
            this.conferenceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    initTimer() {
        const source = timer(10000, 10000);
        this.timerSubscription = source.subscribe(val => {
            this.getGuestURL();
        });
        this.currentContext.isTimerOn = true;
    }

    unSubscribeTimer() {
        // unsubscribe to ensure no memory leaks
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.currentContext.isTimerOn = false;
        }
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.unSubscribeTimer();
    }

}
