export enum EmpAddressFilter {
    empAddressId,
    clientId,
    organizationId,
    institutionId,
    empId,
    houseNo,
    address,
    countryId,
    stateId,
    cityId,
    pincode,
    phoneNo,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    districtId,
}
export enum EmpDocumentFilter {
    empDocumentId,
    clientId,
    organizationId,
    institutionId,
    empId,
    documentTypeId,
    documentDate,
    filePath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    comments,
}
export enum EmpEarningFilter {
    empEarningId,
    clientId,
    organizationId,
    institutionId,
    empId,
    basic,
    hra,
    da,
    conveyance,
    otherAllowance,
    ltaAll,
    incentives,
    otPerHour,
    otPerDay,
    isTds,
    tds,
    from,
    to,
    isProfessionalTax,
    professionalTax,
    professionalTaxDurationId,
    esicEmployerContribution,
    esicEmployeeContribution,
    pfEmployerContribution,
    pfEmployeeContribution,
    otherDeductions,
    remarks,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    yearlyEarning,
    yearlyDeduction,
    yearlyNetSalary,
    yearlyTds,
    yearlyEsiEmployee,
    yearlyPfEmployee,
    totalEarning,
    totalDeductions,
    netSalary,
    perDaySalary,
}
export enum EmpEducationFilter {
    empEducationId,
    clientId,
    organizationId,
    institutionId,
    empId,
    educationLevelId,
    course,
    institution,
    from,
    to,
    percentage,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpGuardianFilter {
    empGuardianId,
    clientId,
    organizationId,
    institutionId,
    empId,
    name,
    relation,
    qualification,
    occupation,
    totalIncome,
    mobileNo,
    phoneNo,
    emailId,
    officeAddress,
    homeAddress,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpleaverequestFilter {
    empLeaveRequestId,
    clientId,
    organizationId,
    institutionId,
    empId,
    leaveTypeId,
    requestedNoOfDays,
    fromDate,
    toDate,
    leaveForId,
    reportingManagerId,
    reason,
    rejectReason,
    requestDate,
    empLeaveRequestStatusId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    approvedDate,
    rejectedDate,
}
export enum EmpLeaveFilter {
    empLeaveId,
    clientId,
    organizationId,
    institutionId,
    empId,
    leaveTypeId,
    academicYearId,
    allocatedLeaves,
    approvedLeaves,
    availableLeaves,
    leavesToBeAccrued,
    leavesCarriedForward,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    isActive,
}
export enum EmployeeFilter {
    empId,
    clientId,
    organizationId,
    institutionId,
    titleId,
    genderId,
    firstName,
    middleName,
    lastName,
    aliasName,
    dob,
    birthPlace,
    nationalityId,
    religion,
    maritalStatusId,
    mobileNo,
    bloodGroupId,
    categoryId,
    doj,
    departmentId,
    designationId,
    totalExperienceYear,
    totalExperianceMonth,
    employmentStatusId,
    employmentModeId,
    reportingToId,
    employeeNo,
    employeeStatusId,
    relievingDate,
    terminationReason,
    loginAccess,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    userId,
    shiftTypeId,
    staffTypeId,
    email,
    groupId,
    leaveGroupId,
    imagePath,
    isActive,
    aadharNo,
    homePhoneNo,
    officePhoneNo,
    bankName,
    bankAccountNo,
    ifscCode,
    religionId,
    totalExperiencedYear,
    totalExperiancedMonth
}
export enum EmpMemoFilter {
    empMemoId,
    clientId,
    organizationId,
    institutionId,
    empId,
    empName,
    empNo,
    designationId,
    departmentId,
    memoTypeId,
    memoCreatedById,
    memoDate,
    memoDetails,
    memoStatusId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpOfferLetterFilter {
    empOfferLetterId,
    clientId,
    organizationId,
    institutionId,
    empId,
    typeId,
    templateId,
    letter,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpOtherInfoFilter {
    empOtherInfoId,
    clientId,
    organizationId,
    institutionId,
    empId,
    attendenceCardId,
    motherName,
    reference,
    specialization,
    languages,
    hobbies,
    panNo,
    esicNo,
    pfNo,
    bankAccountNo,
    bankId,
    ifscCode,
    passportNo,
    expiryDate,
    drivingLicenseNo,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmpSubjectFilter {
    empSubjectId,
    clientId,
    organizationId,
    institutionId,
    empId,
    subjectId,
    batchId,
    inCharge,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum PayslipFilter {
    payslipId,
    clientId,
    organizationId,
    institutionId,
    empId,
    staffTypeId,
    designationId,
    departmentId,
    presentDays,
    absentDays,
    leaveDays,
    lop,
    basic,
    hra,
    da,
    conveyance,
    otherAllowance,
    incentives,
    otPerDay,
    tds,
    esicEmployeeContribution,
    pfEmployeeContribution,
    otherDeductions,
    loan,
    totalEarning,
    totalDeductions,
    netSalary,
    perDaySalary,
    payslipStatusId,
    isReleasedToEmp,
    month,
    year,
    monthName,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    lopDeduction,
    ltaAll,
    esicEmployerContribution,
    pfEmployerContribution
}
