import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable({
    providedIn: 'root',
})
export class ItemCountService {
    constructor(public utl: UtilService) { }

    getData(config) {
        var options: any = {
            action: 'itemcount/getItemCount',
            type: 'post',
            data: config.data,
            callback: config.callback
        };

        return this.utl.http.doAction(options);
    }
}