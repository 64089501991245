import { Component, Input } from '@angular/core';
import { ProjectUtil } from '../../application/services/project-util';
import * as moment from 'moment';

@Component({
  selector: 'currency-format',
  templateUrl: './currency-format.component.html',
  styleUrls: ['./currency-format.component.scss']
})

export class CurrencyFormatComponent {

  public _currencyval: any;
  public currencyFormat:any;

  @Input()
  set currencyval(inputData: any) {
      this.getCurrencyFormat();
      this._currencyval = inputData;
  }

  get currencyval(): any { return this._currencyval; }

  constructor(private projectUtil: ProjectUtil) { }

  ngOnInit() {
  }

  getCurrencyFormat() {
    this.currencyFormat = this.projectUtil.getPreferenceCurrencyFormat();
  }
}
