import { Component, ViewChildren, QueryList, Input, ViewChild, TemplateRef } from '@angular/core';
import { OptionsService, UtilService, FileUploadComponent, MessageService } from 'fx-core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { EmpDocumentFilter } from '../../application/enums';
import { EmpDocumentModalComponent } from '../emp-document-modal/emp-document-modal.component';
import { EmpDocumentService } from '../services/empdocument.service';

@Component({
    selector: 'emp-document',
    templateUrl: 'emp-document.component.html',
    styleUrls: ['emp-document.component.scss']
})

export class EmpDocumentComponent {   
    @Input() contextData: any;
    @Input() modalData: any; 
    @Input() routeData: any; 
    
    lookup: any = {};
    formModel: any = {
        empId: -1,
        documentTypeId: -1,
        comments: ''
    };

    currentContext: any = {
        empId: null
    }
    items:any = [];
    subscription: Subscription;
    
    constructor(protected utl: UtilService, private optionsService: OptionsService, 
            public messageService: MessageService,
            private modalService: BsModalService, private empDocumentService: EmpDocumentService) {     
                
        this.subscription = this.messageService.getMessage().subscribe(res => {
            if (res && res.type == 'emp-document-added') {
               this.refreshDocuments();
            }
        });
    }

    ngOnInit() {
        console.log('routeData=', this.routeData);
        this.currentContext.empId = this.routeData.empId || this.routeData.id;
        this.formModel.empId = this.currentContext.empId;
        this.getList();
    }

    getList() {
        if(this.currentContext.empId > 0) {
            let apiReq = {
                filters: [
                    { key: EmpDocumentFilter.empId, value: this.currentContext.empId },
                ],
                include: [{ key: "documentType" }]
            };
            this.empDocumentService.getEmpDocuments(apiReq).then(data => this.getListCallback(data));
        }
    }
    getListCallback(res) {
        this.items = res.data;
    }

    refreshDocuments() {
        this.formModel = {
            empId: this.currentContext.empId,
            documentTypeId: -1,
            comments: ''
        }
        this.getList();
    }

    //delete
    deleteEmpDocument(item) {
        let inputData = {
            id : item.id
        };
        this.empDocumentService.deleteEmpDocument(inputData).then(data => this.deleteEmpDocumentCallback(data));
    }
    deleteEmpDocumentCallback(res) {
        this.utl.alert.showSuccessMsg();
        this.getList();
    }

    openDocumentFormModal() {
        let initialState = {
            uploadFormModel:  Object.assign({}, this.formModel)
        };
        this.modalService.show(EmpDocumentModalComponent, { class: 'custom-modal-md', initialState });
    }
    
    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
}