import { Component, OnInit } from '@angular/core';
import { SyllabusService } from '../services/syllabus.service';
import { SyllabusPlanDetailFilter } from '../../application/enums';
import { UtilService } from 'fx-core';
import * as moment from 'moment';
import { ProjectUtil } from '../../application/services/project-util';

@Component({
  selector: 'staff-syllabus-notice',
  templateUrl: './syllabus.component.html',
  styleUrls: ['./syllabus.component.scss']
})
export class SyllabusNoticeComponent implements OnInit {


  public notices: any = [];
  public student: any = null;

  constructor(public utl: UtilService, public service: SyllabusService, private projectUtil: ProjectUtil) {
    this.student = this.projectUtil.getStudentObj();
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    var auth = this.utl.session.getObject('sessionVariables');

    var inputData = {
      filters: [
        {
          key: SyllabusPlanDetailFilter.statusId, value: 1
        },
        {
          key: SyllabusPlanDetailFilter.planedDate, value: moment().format("YYYY-MM-DD")
        }
      ],
      include: [
        { key: "subject" },
        { key: "course" },
        { key: "batch" },
        { key: "academicYear" }
      ]
    }
    if (this.student && this.student.courseId) {
      inputData.filters.push({ key: SyllabusPlanDetailFilter.courseId, value: this.student.courseId })
    }

    if (auth.myEmpId && auth.myEmpId > 0) {
      inputData.filters.push({
        key: SyllabusPlanDetailFilter.staffId, value: auth.myEmpId
      })
    }
    this.service.GetSyllabusOneDay(inputData).then(res => this.getListCallback(res));
  }

  getListCallback(res) {
    this.notices = res.data;
  }

}
