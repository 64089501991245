export enum CertificationFilter {
    certificationId,
    clientId,
    organizationId,
    institutionId,
    code,
    certification,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum DesignationFilter {
    designationId,
    clientId,
    organizationId,
    institutionId,
    code,
    designation,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EducationFilter {
    educationId,
    clientId,
    organizationId,
    institutionId,
    code,
    education,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmploymentStatusFilter {
    employmentStatusId,
    clientId,
    organizationId,
    institutionId,
    code,
    employmentStatus,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum HrmTemplateFilter {
    hrmTemplateId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    templateTypeId,
    template,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LeaveGroupLeaveTypeMapFilter {
    leaveGroupLeaveTypeMapId,
    clientId,
    organizationId,
    institutionId,
    leaveGroupId,
    leaveTypeId,
    noOfDays,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LeaveGroupFilter {
    leaveGroupId,
    clientId,
    organizationId,
    institutionId,
    code,
    leaveGroup,
    details,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LeavePeriodFilter {
    leavePeriodId,
    clientId,
    organizationId,
    institutionId,
    code,
    leavePeriod,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    startDate,
    endDate,
}
export enum LeaveRuleFilter {
    leaveRuleId,
    clientId,
    organizationId,
    institutionId,
    name,
    leaveTypeId,
    leavesPerLeavePeriod,
    designationId,
    leaveGroupId,
    canApplyBeyondCurrentLeaveBalnce,
    carryForwardPercentage,
    maxCarryForwardAmount,
    carryForwardLeaveAvailPeriodId,
    canEmployeeApply,
    isProportionateOnJoinedDate,
    isLeaveCarryForward,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LeaveTypeFilter {
    leaveTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    leaveType,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LoanTypeFilter {
    loanTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    loanType,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum PayGradeFilter {
    payGradeId,
    clientId,
    organizationId,
    institutionId,
    code,
    payGrade,
    minSalary,
    maxSalary,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SkillFilter {
    skillId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    alias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
