import { Injectable } from '@angular/core';
import { SessionStorageService, Constants } from 'fx-core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProjectUtil {

  constructor(public session: SessionStorageService) {
  }

  getCurrentAcademicYear() {
    let currentAcademicYearId = this.session.getSessionVariable("currentAcademicYearId");
    currentAcademicYearId = currentAcademicYearId ? parseInt(currentAcademicYearId) : -1;
    return currentAcademicYearId;
  }

  getMyCourseId() {
    let myCourseId = this.session.getSessionVariable("myCourseId");
    myCourseId = myCourseId ? parseInt(myCourseId) : -1;
    return myCourseId;
  }
  getMyBatchId() {
    let myBatchId = this.session.getSessionVariable("myBatchId");
    myBatchId = myBatchId ? parseInt(myBatchId) : -1;
    return myBatchId;
  }
  getMySectionId() {
    let mySectionId = this.session.getSessionVariable("mySectionId");
    mySectionId = mySectionId ? parseInt(mySectionId) : -1;
    return mySectionId;
  }
  getMyEmpId() {
    let myEmpId = this.session.getSessionVariable("myEmpId");
    myEmpId = myEmpId ? parseInt(myEmpId) : -1;
    return myEmpId;
  }  
  getConferenceRecordingPermission() {
    let conferenceRecordingPermission = this.session.getSessionVariable("ConfRecordingEnabled");
    conferenceRecordingPermission = conferenceRecordingPermission ? parseInt(conferenceRecordingPermission) : 0;
    return conferenceRecordingPermission;
  }
  getBaseURI() {
    let baseURI = this.session.getObject("baseURI");
    return baseURI;
  }

  getStudentObj() {
    let studentObj = this.session.getObject("studentObj");
    return studentObj;
  }
  
  isFutureDate(inputDate) {
      inputDate = moment(inputDate).format("YYYY-MM-DD");
      var currentDate = moment().format("YYYY-MM-DD");

      var result = moment(inputDate).isAfter(currentDate);
      return result;
  }

  getMonths(fromDate, toDate) {
    let startDate = moment(fromDate);
    let endDate = moment(toDate);

    let result = [];
    let idVar = 1;
    result.push({ id: -1, text: "Please select...", fromDate: null, toDate: null });
    while (startDate < endDate) {
      const month = startDate.format('MMM-YYYY');
      result.push({
        id: idVar, text: month, fromDate: moment(startDate).startOf('month').toDate(),
        toDate: moment(startDate).endOf('month').toDate()
      });
      startDate.add(1, 'month');
      idVar++;
    }
    return result;
  }

  getCurrentMonthId(months) {
      let currentMonthText =  moment().format('MMM-YYYY');
      let currentMnth: any = _.find(months, {text : currentMonthText});
      if(currentMnth) {
          return currentMnth.id;
      }
      return;
  }

  getDefaultMonthId() {
    let monthId = this.session.getSessionVariable("currentMonthId");
    monthId = monthId ? parseInt(monthId) : -1;
    return monthId;
  }

  getDefaultYearId() {
    let yearId = this.session.getSessionVariable("currentYearId");
    yearId = yearId ? parseInt(yearId) : -1;
    return yearId;
  }

  getPreferenceDateFormat() {
    const datePrefVal = this.session.getSessionVariable("displaydate");
    const dateFormat = datePrefVal ? datePrefVal : Constants.DateFormat;
    return dateFormat;
  }

  getPreferenceCurrencyFormat() {
    const prefVal = this.session.getSessionVariable("currency");
    const currency = prefVal ? prefVal : "INR";
    return currency;
  }
}
