import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class ConferenceService {
    constructor(public utl: UtilService) {
    }

    getGuestJoinUrl(inputData) {
        var options: any = {
            action: 'videoconference/guestconference',
            type: 'post',
            data: inputData,
            ignoreProgressBar: true
        };

        return this.utl.http.doAction(options);
    }
    
}