import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class ProfileService {
    constructor(public utl: UtilService) {
    }

    GetMyProfile() {
        var options: any = {
            action: '/user/GetMyProfile',
            type: 'post',
            data: null
        };
        return this.utl.http.doAction(options);
     }

     GetEmployeeProfile() {
        var options: any = {
            action: 'hrmemp/employee/getEmployeeProfileDetails',
            type: 'post',
            data: {}
        };
        return this.utl.http.doAction(options);
     }

     GetStudentProfile() {
        var options: any = {
            action: 'student/student/getStudentProfileDetails',
            type: 'post',
            data: {}
        };
        return this.utl.http.doAction(options);
     }

     GetParentProfile() {
        var options: any = {
            action: 'student/student/getParentProfileDetails',
            type: 'post',
            data: {}
        };
        return this.utl.http.doAction(options);
     }
    
}