export enum BankFilter {
    bankId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    serviceCharge,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum CardtypeFilter {
    cardTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    serviceChargeCreditCard,
    serviceChargeDebitCard,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum FeeGroupFilter {
    feeGroupId,
    clientId,
    organizationId,
    institutionId,
    feeGroupCode,
    feeGroupName,
    startDate,
    endDate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    academicYearId,
    dueDate
}
export enum FeeFilter {
    feeId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    academicYearId,
    feeServiceId,
    feeGroupId,
    amount,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    feeCategoryId,
    welfareAmount,
}
export enum FeeServiceFilter {
    feeServiceId,
    clientId,
    organizationId,
    institutionId,
    serviceCode,
    serviceName,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum CautionDepositFilter {
    cautionDepositId,
    clientId,
    organizationId,
    institutionId,
    academicYearId,
    courseId,
    batchId,
    amount,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum FeeCategoryFilter {
    feeCategoryId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum FineFilter {
    fineId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    academicYearId,
    courseId,
    batchId,
    feeGroupId,
    from,
    to,
    fineAmount,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
