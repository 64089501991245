import { Component, OnInit } from '@angular/core';
import { ItemCountService } from '../../../services/itemcount.service';
import { TranslateService } from '@ngx-translate/core';
import { StudentAttendanceService } from '../../services/studentattendance.service';
import { UtilService } from 'fx-core';

@Component({
  selector: 'portlet-today-attendance-report',
  templateUrl: './portlet-today-attendance-report.component.html',
  styleUrls: ['./portlet-today-attendance-report.component.scss']
})
export class PortletTodayAttendanceReportComponent implements OnInit {

  reportDetails: any[] = [];
  totalStudents: any = 0;
  totalAttendanceReport: any = 0;

  constructor(protected utl: UtilService, private translate: TranslateService,
    private studentAttendanceService: StudentAttendanceService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    const inputData = {

    };
    this.studentAttendanceService.GetTodaysReport(inputData).then(data => this.getDataCallback(data));
  }

  getDataCallback(res) {
    this.reportDetails = res.data;
    let totalStd = 0;
    let presentStd = 0;
    this.reportDetails.forEach((i: any) => {
      if (i.totalStudentCount) {
        totalStd += i.totalStudentCount
      }

      if (i.presentCount) {
        presentStd += i.presentCount
      }
    });
    this.totalAttendanceReport = (presentStd).toFixed(2);
    this.totalStudents = (totalStd).toFixed(2);
  }

}
