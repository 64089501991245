export enum ClientFilter {
    clientId,
    code,
    name,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ControlFilter {
    controlId,
    parentControlId,
    moduleId,
    moduleCode,
    context,
    controlCode,
    parentControlCode,
    controlType,
    controlPosition,
    display,
    sRef,
    iconRef,
    translateRef,
    params,
    displayOrder,
    isActive,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    rev,
    targetType
}
export enum DefaultPreferenceFilter {
    defaultPreferenceId,
    preferenceKey,
    defaultPreferenceValue,
    preferenceScope,
    preferenceType,
    controlType,
    displayField,
    valueField,
    lookupKey,
    description,
    helpText,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum DefaultTemplateFilter {
    defaultTemplateId,
    templateKey,
    templateIdentifier,
    templateTypeCode,
    header,
    footer,
    template,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    templateConfig,
}
export enum DepartmentFilter {
    departmentId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    departmentTypeId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EventFilter {
    eventId,
    clientId,
    organizationId,
    institutionId,
    startDate,
    endDate,
    eventSubject,
    eventDescription,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    academicYearId
}
export enum GroupInstitutionMapFilter {
    groupId,
    institutionId,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum GroupRoleMapFilter {
    groupId,
    roleId,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum GroupFilter {
    groupId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum InstitutionFilter {
    institutionId,
    clientId,
    organizationId,
    code,
    name,
    description,
    institutionTypeId,
    addressLine1,
    addressLine2,
    area,
    countryId,
    stateId,
    districtId,
    pincode,
    landline,
    faxNo,
    mobileNo,
    emailId,
    headOfInstitution,
    hoiContactNo,
    hoiEmailId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    licenseValidFrom,
    licenseValidTo,
    inactiveReason,
    modules,
    meetBaseUri,
    meetSecret
}
export enum MessageFilter {
    messageId,
    clientId,
    organizationId,
    institutionId,
    messageDate,
    messageDescription,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum NoticeFilter {
    noticeId,
    clientId,
    organizationId,
    institutionId,
    noticeDate,
    noticeTypeId,
    noticeSubject,
    noticeDescription,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    messageValidity,
}
export enum OrganizationFilter {
    organizationId,
    clientId,
    code,
    name,
    description,
    organizationTypeId,
    addressLine1,
    addressLine2,
    area,
    countryId,
    stateId,
    districtId,
    pincode,
    landline,
    faxNo,
    mobileNo,
    emailId,
    contactPerson,
    contactNo,
    isActive,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    rev,
}
export enum PreferenceFilter {
    preferenceId,
    clientId,
    organizationId,
    institutionId,
    defaultPreferenceId,
    userId,
    preferenceType,
    preferenceKey,
    preferenceValue,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ReferenceGroupFilter {
    referenceGroupId,
    clientId,
    organizationId,
    institutionId,
    groupCode,
    groupName,
    isSystem,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum ReferenceValueFilter {
    referenceValueId,
    clientId,
    organizationId,
    institutionId,
    referenceGroupId,
    referenceGroupCode,
    referenceValueCode,
    referenceValueText,
    referenceValueCodeId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    isActive,
}
export enum RoleControlMapFilter {
    roleId,
    controlId,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RoleInstitutionMapFilter {
    roleId,
    institutionId,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RoleFilter {
    roleId,
    clientId,
    organizationId,
    institutionId,
    roleCode,
    roleName,
    roleDetails,
    landingControlId,
    isActive,
    rev,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum TemplateFilter {
    templateId,
    clientId,
    organizationId,
    institutionId,
    templateKey,
    defaultTemplateId,
    header,
    footer,
    template,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    templateConfig,
}
export enum UserFilter {
    userId,
    clientId,
    organizationId,
    institutionId,
    userName,
    password,
    groupId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    otp,
    pushToken,
    isActive
}

export enum ModuleFilter {
    moduleId,
    moduleCode,
    moduleName,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}

export enum DefaultReportFilter {
    defaultReportId,
    reportKey,
    reportName,
    query,
    countQuery,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    spName,
}

export enum ReportFilter {
    reportId,
    clientId,
    organizationId,
    institutionId,
    reportKey,
    defaultReportId,
    query,
    countQuery,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    spName,
}
export enum EmailLogFilter {
    emailLogId,
    clientId,
    organizationId,
    institutionId,
    subject,
    message,
    messageDate,
    senderId,
    receiverId,
    emailStatus,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EmailSettingFilter {
    emailSettingId,
    clientId,
    organizationId,
    institutionId,
    providerName,
    userName,
    password,
    senderId,
    maxEmailPerMonth,
    consumedEmail,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SmsLogFilter {
    smsLogId,
    clientId,
    organizationId,
    institutionId,
    message,
    messageDate,
    senderId,
    receiverId,
    smsStatus,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SmsSettingFilter {
    smsSettingId,
    clientId,
    organizationId,
    institutionId,
    providerName,
    userName,
    password,
    senderId,
    maxSmsPerMonth,
    consumedSms,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum PrivilegeFilter {
    privilegeId,
    moduleId,
    moduleCode,
    privilegeCode,
    displayName,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RolePrivilegeMapFilter {
    rolePrivilegeId,
    clientId,
    organizationId,
    institutionId,
    roleId,
    roleCode,
    privilegeId,
    privilegeCode,
    moduleCode,
    isEnabled,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}