import { Injectable } from "@angular/core";
import * as filterEnums from './index';
import { UtilService } from "fx-core";

@Injectable({
    providedIn: 'root',
})

export class FilterMapService {
    constructor(public utl: UtilService) { }

    cascadingEnumMap = {
        Country: 'CountryFilter',
        State: 'StateFilter',
        District: 'DistrictFilter',
        City: 'CityFilter',
        Organization: 'OrganizationFilter',
        Client: 'ClientFilter',
        Course: 'CourseFilter',
        Batch: 'BatchFilter',
        Section: 'SectionFilter',
        Block: 'BlockFilter',
        Floor: 'FloorFilter',
        RoomMaster: 'RoomMasterFilter',
        RoomBed: 'RoomBedFilter',
        Test: 'TestFilter',
        Student: 'StudentFilter',
        Subject: 'SubjectFilter',
        TeachingStaff: 'EmployeeFilter',
        AssessmentForm: 'AssessmentFormFilter'
    };
    
    computeFilters(apiConfig) {
        //console.log(filterEnums['DepartmentFilter']);

        var filterModel = apiConfig.inputparams.filters;
        var filterOptions = apiConfig.inputparams.filterOptions || {};
        var filterEnumKey = apiConfig.filterEnum;

        if(!filterEnumKey) {
            return filterModel;
        }
        
        var result = [];
        for(var filterKey in filterModel) {
            var filterKeyInt =  filterEnums[filterEnumKey][filterKey];
            var filterValue = filterModel[filterKey];

            if(filterValue != -1 && (filterValue === 0 || filterValue)) {
                var filterParam: any = { key: filterKeyInt, value: filterValue};
                if(filterOptions[filterKey] && filterOptions[filterKey].searchType) {
                    filterParam.searchType = filterOptions[filterKey].searchType == 'contains'? 1 : 2; 
                }
                result.push(filterParam);
            }
        }
        return result;
    }

    computeLookups(data) {
       
        var lookups = data.lookups;
        var filters = data.filters;
        var result = [];
     
        for(var key in lookups){
            var item = lookups[key];
            var param: any = { key : key, default: item.default, request: {} };
            var filterEnumKey= this.cascadingEnumMap[key];

            if(filters) {
                var newFilters = [];
                for(var fkey in filters) {
                    var fvalue = filters[fkey];
                    var filterKeyInt =  filterEnums[filterEnumKey][fkey];

                    var newFilter = { key : filterKeyInt, value: fvalue};
                    newFilters.push(newFilter);
                }
               param.request['filters'] =  newFilters;
            }
            if(item.filters) { // this is used from preference-from
                param.request['filters'] =  item.filters;
            }
            result.push(param);
        }
        return result;
    }

}