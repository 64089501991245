import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';

@Injectable()
export class StudentDocumentService {
    constructor(public utl: UtilService) {
    }

    getStuDocuments(inputData) {
        var options: any = {
            action: 'student/studentDocument/getStudentDocuments',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    deleteStuDocument(inputData) {
        var options: any = {
            action: 'student/studentDocument/deleteStudentDocument',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getItems(url){
        var options: any = {
            action: url,
            type: 'get',
        };

        return this.utl.http.doAction(options);

    }

    openFile(inputData, URL) {
        var options: any = {
            action: URL,
            type: 'get',
            data: inputData
        };
        console.log(options)

        return this.utl.http.doDownload(options);
    }
}