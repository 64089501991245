export enum BlockFilter {
    blockId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EbChargeFilter {
    ebChargeId,
    clientId,
    organizationId,
    institutionId,
    ebNoId,
    chargePerUnit,
    minUnit,
    maxUnit,
    additionalCharge,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum EbNoFilter {
    ebNoId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum FloorFilter {
    floorId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    blockId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum HostelFeeDetailFilter {
    hostelFeeDetailId,
    clientId,
    organizationId,
    institutionId,
    hostelFeeId,
    name,
    details,
    amount,
    discount,
    netAmount,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum HostelFeePaymentFilter {
    hostelFeePaymentId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    academicYearId,
    courseId,
    batchId,
    hostelFeeId,
    monthId,
    yearId,
    paymentDate,
    paymentMode,
    transactionTypeId,
    chequeNo,
    bank,
    bankBranch,
    cardNumber,
    cardHolderName,
    amount,
    cashierId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum HostelFeeFilter {
    hostelFeeId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    yearId,
    monthId,
    hostelTypeId,
    blockId,
    floorId,
    roomMasterId,
    roomBedId,
    amount,
    paid,
    paymentStatusId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum HostelTypeFilter {
    hostelTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RoomBedFilter {
    roomBedId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    blockId,
    floorId,
    roomMasterId,
    bedStatusId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RoomEbReadingFilter {
    roomEbReadingId,
    clientId,
    organizationId,
    institutionId,
    hostelTypeId,
    blockId,
    floorId,
    roomMasterId,
    noOfBeds,
    yearId,
    monthId,
    ebNoId,
    openingReading,
    closingReading,
    noOfUnits,
    chargePerUnit,
    totalCharges,
    additionalCharges,
    chargePerBed,
    dueDate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RoomFilter {
    roomId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    blockId,
    floorId,
    roomTypeId,
    noOfBeds,
    ebNoId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum RoomTypeFilter {
    roomTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    roomCharge,
    maintenance,
    foodCharge,
    others,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum StudentRoomFilter {
    studentRoomId,
    clientId,
    organizationId,
    institutionId,
    studentId,
    academicYearId,
    hostelTypeId,
    blockId,
    floorId,
    roomMasterId,
    roomBedId,
    roomTypeId,
    ebNoId,
    roomCharges,
    maintenanceCharges,
    foodCharges,
    otherCharges,
    comments,
    chargeTypeId,
    graceDays,
    lateFees,
    startDate,
    endDate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    bedStatusId,
}

export enum RoomMasterFilter {
    roomMasterId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    blockId,
    floorId,
    roomTypeId,
    noOfBeds,
    ebNoId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}