import { Component, OnInit, Input } from "@angular/core";
import { OptionsService, UtilService } from "fx-core";
import {
  StudentFilter,
  RouteBoardingPointMapFilter, StudentFeeFilter
} from "../../application/enums";
import { PassengerAssignmentService } from "../../transport/services/passenger-assignment.service";
import { StudentService } from "../../hostelmgmt/services/student.service";

@Component({
  selector: "trans-assignment",
  templateUrl: "transport-assignment.component.html",
  styleUrls: ["transport-assignment.component.scss"],
})
export class SharedPassengerAssignmentComponent implements OnInit {
  lookup: any = {};
  @Input() contextData: any;
  @Input() modalData: any;
  @Input() routeData: any;

  currentFilter: any = {
    name: "",
    uniqueId: "",
  };
  createTransportServiceToStudent: any = {
    academicYearId: "",
    amount: "",
    batchId: "",
    courseId: "",
    feeServiceId: "",
    studentFeeId: "",
    studentId: "",
    welfareAmount: 0
  };
  studentDetails: any = {
    course: {},
    batch: {},
    section: {},
    studentStatus: {},
    city: {},
  };

  item: any = {
    passengerTypeId: 1, // 1 - student
    isActive: true,
  };
  boardingpoints: any = [];
  vehicleDetails: any = {
    driver: {},
    attender: {},
  };
  dropDownData: any = [];
  fare: number = 0;
  transportServiceGroups: any = null;
  serviceTypeId: any = '';

  constructor(
    public optionsService: OptionsService,
    public utl: UtilService,
    public PassengerAssignmentService: PassengerAssignmentService,
    protected studentService: StudentService
  ) { }

  ngOnInit() {
    let routeData = this.utl.nav.peek();
    console.log(routeData, 'trans');

    if (routeData && routeData.targetData) {
      this.item = routeData.targetData;
    }
    this.item.isActive = true;
    this.init();
  }

  init() {
    this.initLookup();
    this.getDetails();
  }

  getDetails() {
    if (this.item.id > 0) {
      // check redirection type if from student set student id
      if (this.item.studentUserId) {
        this.item.studentId = this.item.id;
        if (this.item.routes) {
          this.item.routeId = this.item.routes.id;
        }
      }

      this.getStudentData(this.item.studentId);
      this.routeChange(); // to get vehicle details and boarding points
    }
  }

  studentsDropDown(e, type: string = "", allow: boolean = false) {
    if ((e && e.target && e.target.value) || allow === true) {
      if (type === "N") {
        this.currentFilter.name = e.target.value;
      }

      const filterParams: any = {
        filters: [],
        include: [],
        pageContext: {
          pageNumber: 1,
          pageSize: 50,
        },
      };

      if (this.currentFilter.name) {
        filterParams.filters.push({
          key: 6,
          value: this.currentFilter.name,
          searchType: 1,
        });
      }

      if (filterParams.filters.length > 0) {
        this.studentService.getStudentDetailsByIds(filterParams).then(
          (res) => {
            const { data } = res;
            if (data && data.length > -1) {
              this.dropDownData = data;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  setFilterName(nameSearch, data) {
    this.currentFilter.name = nameSearch;
    this.dropDownData = [];
    this.getStudentData(data.id);
  }

  getStudentData(studentId) {
    let inputData: any = {};
    if (this.currentFilter.name.length > 0 || studentId > 0) {
      inputData = {
        filters: [
          {
            key: StudentFilter.firstName,
            value: this.currentFilter.name,
            searchType: 1,
          },
        ],
        include: [
          { key: "course" },
          { key: "batch" },
          { key: "section" },
          { key: "city" },
        ],
      };
      if (studentId > 0) {
        inputData.filters.push({
          key: StudentFilter.studentId,
          value: studentId,
        });
      }
      this.PassengerAssignmentService.getStudentDetails(inputData).then((res) =>
        this.getStudentDataCallback(res)
      );
    }
  }

  getTransportServices() {
    const inputData = {
      filters: [
        { key: StudentFeeFilter.studentId, value: this.studentDetails.id },
        { key: StudentFeeFilter.academicYearId, value: this.studentDetails.academicYearId }
      ],

      include: [{ key: "feeGroup" }]
    };
    this.PassengerAssignmentService.getTransPortServicesGroup(inputData).then((res) =>
      this.getTransportServicesCallback(res)
    );
  }

  getTransportServicesCallback(res) {
    if (res.data) {
      res.data.forEach(item => {
        if (item.feeGroup && item.feeGroup.feeGroupCode === "transport") {
          this.transportServiceGroups = item;
          this.setFormData()
        }
      });
    }

    if (!this.transportServiceGroups)
      this.utl.alert.showErrorMsg('No transport fees assigned to this student');
  }

  setFormData() {
    if (this.studentDetails.id) {
      this.createTransportServiceToStudent = {
        academicYearId: this.studentDetails.academicYearId,
        amount: this.fare,
        batchId: this.studentDetails.batchId,
        courseId: this.studentDetails.courseId,
        feeServiceId: this.serviceTypeId,
        studentFeeId: this.transportServiceGroups.id,
        studentId: this.studentDetails.id,
        welfareAmount: 0
      };
    } else {
      this.utl.alert.showErrorMsg('Choose a student to assign');
    }
  }

  getStudentDataCallback(res) {
    this.studentDetails = res.data;
    this.item.studentId = this.studentDetails.id;
    this.getTransportServices(); /// >> to get transport data code: transport
  }

  routeChange() {
    this.getBoardingPoints();
    this.getVehicleDetails();
    this.fare = 0; //reset fare
  }

  getBoardingPoints() {
    if (this.item.routeId > 0) {
      let inputData: any = {
        filters: [
          {
            key: RouteBoardingPointMapFilter.routeId,
            value: this.item.routeId,
          },
        ],
        include: [{ key: "boardingPoint" }],
      };
      this.PassengerAssignmentService.getBoardingPoints(inputData).then(
        (data) => this.getBoardingPointsCallback(data)
      );
    }
  }

  getBoardingPointsCallback(res) {
    this.boardingpoints = res.data;
  }

  getVehicleDetails() {
    if (this.item.routeId > 0) {
      let inputData: any = {
        routeId: this.item.routeId,
      };
      this.PassengerAssignmentService.getVehicleDetails(inputData).then((data) =>
        this.getVehicleDetailsCallback(data)
      );
    }
  }

  getVehicleDetailsCallback(res) {
    this.vehicleDetails = res.data;
  }

  savePassengerDetail() {
    if (!this.transportServiceGroups) {
      this.utl.alert.showErrorMsg('No transport fees assigned to this student');
      return false;
    }
    if (this.item.studentId > 0 && this.item.boardingPointId > 0) {
      let inputData = {
        data: this.item,
      };
      this.PassengerAssignmentService.savePassengerDetail(inputData).then(
        (data) => this.savePassengerDetailCallback(data)
      );
    }
  }

  setFareAmt(args: any) {
    if (args.boardingPointId) {
      this.boardingpoints.forEach(board => {
        if (board.boardingPointId == args.boardingPointId && board.boardingPoint) {
          this.fare = board.boardingPoint.amount ? board.boardingPoint.amount : 0
          this.serviceTypeId = board.boardingPoint.serviceTypeId ? board.boardingPoint.serviceTypeId : ''
        }
      });
    }
    this.setFormData();
  }

  savePassengerDetailCallback(res) {
    if (this.fare > 0)
      this.PassengerAssignmentService.addTransportServices({ data: this.createTransportServiceToStudent })

    this.utl.alert.showSuccessMsg();
    this.backToList();
  }

  backToList() {
    this.utl.nav.back();
  }

  initLookup() {
    var lookupConfig = {
      Route: { default: true },
    };
    var inputData = {
      lookups: lookupConfig,
      filters: {},
    };
    var lookupInputData = {
      data: inputData,
      callback: this.processCallback(this.lookup),
    };

    this.optionsService.getData(lookupInputData);
  }

  processCallback(context) {
    return (res) => {
      for (var key in res) {
        context[key] = res[key];
      }
    };
  }
}
