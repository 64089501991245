export enum BookCategoryFilter {
    bookCategoryId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum BookLedgerFilter {
    bookLedgerId,
    clientId,
    organizationId,
    institutionId,
    libraryId,
    bookId,
    bookTitle,
    accessionNo,
    barcodeId,
    ledgerUserTypeId,
    studentId,
    studentName,
    studentIdentifier,
    staffId,
    staffName,
    staffIdentifier,
    courseId,
    batchId,
    sectionId,
    dueDate,
    dueTime,
    issuedById,
    issuedDate,
    issuedTime,
    receivedById,
    receivedDate,
    receivedTime,
    remarks,
    delayedDays,
    fineAmount,
    ledgerStatusId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum BookLocationFilter {
    bookLocationId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum BookRackFilter {
    bookRackId,
    clientId,
    organizationId,
    institutionId,
    bookLocationId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum BookFilter {
    bookId,
    clientId,
    organizationId,
    institutionId,
    bookTypeId,
    bookCategoryId,
    bookTitle,
    author,
    accessionNo,
    barcodeId,
    publication,
    publisherName,
    subject,
    editor,
    volume,
    copyrightYear,
    topic,
    isbn,
    source,
    class,
    noOfBooks,
    billNo,
    billDate,
    cost,
    withdrawDate,
    remarks,
    libraryId,
    bookLocationId,
    bookRackId,
    bookStatusId,
    shelf,
    attachmentPath,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    currentBookStatusId,
}
export enum BookTypeFilter {
    bookTypeId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LibraryInchargeMapFilter {
    libraryInchargeMapId,
    clientId,
    organizationId,
    institutionId,
    libraryId,
    inchargeId,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum LibraryFilter {
    libraryId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    description,
    location,
    inchargeId,
    finePerDay,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    dueDays
}